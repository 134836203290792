<ng-container *transloco="let t;">
<h2>{{ t('partials.slotselector.schedule_interview_time') }}</h2>

<div class="uk-width-1-1" *ngIf="slotDataLoading === true">
	<p><span uk-spinner></span>&nbsp;&nbsp;&nbsp;{{ t('partials.slotselector.loading_data') }}</p>
</div>

<div *ngIf="slotDataLoading === false">
	<div *ngIf="slotData !== null && slotData.length > 0">
		<p *ngIf="mode === 'fallback-select'">{{ t('partials.slotselector.interview_time_not_available') }}</p>
		<div class="uk-margin-bottom">
			<ul uk-tab>
				<li *ngFor="let day of slotData" (click)="setDay(day)" [ngClass]="{'uk-active': day === selectedDay}"><a>{{day.date_start | sbDate: 'DATE_SHORT'}}</a></li>
			</ul>
		</div>
		<div>
			<div uk-grid class="uk-child-width-1-2@m">
				<div>
					<p class="headline">{{ t('partials.slotselector.morning') }}</p>
					<div uk-grid class="uk-grid-small uk-child-width-1-3 uk-child-width-1-3@m slot-list">
						<div *ngFor="let slot of selectedDay.slots" [hidden]="isMorning(slot) !== true" (click)="setSlot(slot)" [ngClass]="{'active': slot === selectedSlot}"><span><span uk-icon="icon: check; ratio: 1.5"></span></span><br>{{slot | sbDate: 'TIME_SHORT'}}</div>
					</div>
				</div>
				<div>
					<p class="headline">{{ t('partials.slotselector.afternoon') }}</p>
					<div uk-grid class="uk-grid-small uk-child-width-1-3 uk-child-width-1-3@m slot-list">
						<div *ngFor="let slot of selectedDay.slots" [hidden]="isAfternoon(slot) !== true" (click)="setSlot(slot)" [ngClass]="{'active': slot === selectedSlot}"><span><span uk-icon="icon: check; ratio: 1.5"></span></span><br>{{slot | sbDate: 'TIME_SHORT'}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div *ngIf="slotData === null || slotData.length === 0">
		<p>{{ t('partials.slotselector.all_interview_times_booked_out') }}</p>
	</div>

</div>

</ng-container>
