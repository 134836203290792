import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sb-ui-apploader',
  templateUrl: './apploader.component.html',
  styleUrls: ['./apploader.component.scss']
})
export class ApploaderComponent implements OnInit {

  @Input() loading: boolean;
  @Input() message: string;

  constructor() { }

  ngOnInit() {
  }

}
