import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sb-ui-emptyhint',
  templateUrl: './emptyhint.component.html',
  styleUrls: ['./emptyhint.component.scss']
})
export class EmptyhintComponent implements OnInit {
  @Input() message: string = null;
  @Input() icon = 'info';
  @Input() full = false;

  constructor() { }

  ngOnInit() {
  }

}
