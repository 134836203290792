import { Injectable } from '@angular/core';
import { AppCoreService } from 'projects/sb-lib/src/lib/modules/core/services/app-core.service';

@Injectable({
  providedIn: 'root'
})
export class AppService extends AppCoreService {

	public showTopEvent(): boolean {
		return !!(this.getValue('website_show_top_event'));
	}

  public getFooterContent(): string {
    return this.getValue('website_footer') || '';
  }

  public getNoEventContent(): string {
    return this.getValue('website_info_noevent') || '';
  }

}
