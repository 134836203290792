import { Deserializable } from './deserializable.model';

export class HiringmanagerModel implements Deserializable {
	// columns
	id: string;
	firstname: string;
	lastname: string;
	email: string;
	phone: string;
	function: string;
	external_id: string;
	external_name: string;
	location_access: Array<string>;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	get fullname(): string {
		return this.firstname + ' ' + this.lastname;
	}

	get fullname_reverse(): string {
		return this.lastname + ', ' + this.firstname;
	}

}



