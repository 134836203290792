import { Component, OnInit } from '@angular/core';
import { AppService } from './services/app.service';
import { ApiService } from './services/api.service';
import { Title } from '@angular/platform-browser';

import * as moment from 'moment';
import { TranslocoService } from '@ngneat/transloco';
import { forkJoin } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	loading = true;
	error = false;
	errorMessage = '';

	constructor(
		private app: AppService,
		private api: ApiService,
		private title: Title,
    private transloco: TranslocoService,
    private primeConfig: PrimeNGConfig
	) { }

	ngOnInit() {
    forkJoin([
      this.api.getSystemSetup(),
      this.api.getEnums()
    ]).subscribe((result: Array<any>) => {
				this.app.setSetupData(result[0]);
				this.app.setEnums(result[1]);
    		moment.locale(result[0].default_language || 'en');
        forkJoin([
          this.transloco.selectTranslation(result[0].default_language),
        ]).subscribe(() => {
          this.transloco.setActiveLang(result[0].default_language);
          this.primeConfig.setTranslation(this.transloco.translateObject('sblib.primeng'));
          this.title.setTitle(this.app.getWebsiteTitle());
        });
			},
			() => {
				this.error = true;
			}
		).add(() => {
			this.loading = false;
		});
	}

}
