import { Deserializable } from './deserializable.model';
import { META_ROLES } from './constants';

export class UserModel implements Deserializable {
	// columns
	id: string;
	firstname: string;
	lastname: string;
	email: string;
	password: string;
	password2: string;
	meta_role: string;
	email_verified_at: number;
	avatar_image: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

	get fullname(): string {
		return this.firstname + ' ' + this.lastname;
	}

	get fullname_reverse(): string {
		return this.lastname + ', ' + this.firstname;
	}

	get real_avatar_image() {
		return (this.avatar_image !== null) ? this.avatar_image : 'assets/layout/images/avatar.jpg';
	}

	get rolename() {
		return META_ROLES[this.meta_role];
	}

}



