import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, UntypedFormControl, Validators, AbstractControl } from '@angular/forms';

import { ApiService } from '../../../../src/app/services/api.service';

import { FormhelperService } from '../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';
import { CandidateEventModel } from '../../../../../sb-lib/src/lib/models/candidateevent.model';
import { JobtypeModel } from '../../../../../sb-lib/src/lib/models/jobtype.model';
import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';

const minLengthArray = (min: number) => {
	return (c: AbstractControl): {[key: string]: any} => {
		if (c.value.filter((value: any) => value.element === true).length >= min) {
			return null;
		}
		return { MinLengthArray: true};
	}
}

@Component({
	selector: 'app-skillselector',
	templateUrl: './skillselector.component.html',
	styleUrls: ['./skillselector.component.scss']
})
export class SkillselectorComponent implements OnInit, OnChanges {

	loading: boolean = null;
	jobData: any = null;
	jobtype: JobtypeModel = null;

	skills: Array<any> = [];
	languages: Array<any> = [];

	skillsForm: UntypedFormGroup = new UntypedFormGroup({
	});

	options: Array<any>;

	constructor(
		private api: ApiService,
		private fb: UntypedFormBuilder,
		private fh: FormhelperService
	, public transloco: TranslocoService) { }

	@Input() mode = 'my-event';
	@Input() event: EventModel = null;
	@Input() jobtypeId: string = null;
	@Input() candidateevent: CandidateEventModel = null;
	@Input() showValidation = false;
	@Output() set = new EventEmitter<any>();
	@Output() cancelled = new EventEmitter<any>();
	@Output() changed = new EventEmitter<any>();

	ngOnInit() {
		this.loading = true;
		this.api.getJobdata(this.event.id).subscribe(result => {
			this.jobData = result;
			this.loading = false;
			if (this.candidateevent !== null && this.candidateevent.jobtype_id !== null) {
				this.jobtypeId = this.candidateevent.jobtype_id;
			}
			// build form
			this.skillsForm = this.fb.group({
				skills: this.fb.array([]),
				languages: this.fb.array([])
			});
			this.skillsForm.valueChanges.subscribe(() => {
				this.changed.emit({
					skills: this.getSkills(),
					languages: this.getLanguages(),
					valid: this.skillsForm.valid
				});
			});
			this.buildForm();
		});
		this.fh.validateAllFormFields(this.skillsForm);
	}

	ngOnChanges(changes: any) {
		this.buildForm();
	}

	buildForm() {
		this.options = [];
		if (this.jobData !== null && parseInt(this.jobtypeId, 10) > 0) {
			this.jobtype = this.jobData.jobtypes.find((element: any) => element.id === this.jobtypeId);
			this.buildDataArrays();
			// set form values
			let candidateSkills: Array<any> = [];
			let candidateLanguages: Array<any> = [];
			if (this.candidateevent !== null) {
				candidateSkills = this.candidateevent.interview_data['candidateData']['competences'];
				candidateLanguages = this.candidateevent.interview_data['candidateData']['languages'];
			}
			if (this.skills !== null && this.skills.length > 0) {
				let skillEntries: UntypedFormArray;
				skillEntries = this.skillsForm.get('skills') as UntypedFormArray;
				while (skillEntries.length > 0) {
					skillEntries.removeAt(0);
				}
				this.skills.forEach((skill: any, index: number) => {
					const validatorType = (skill.type === 'checkbox' || skill.type === 'yes_no') ? Validators.requiredTrue : Validators.required;
					const validators = (skill.mandatory === true) ? [validatorType] : [];
					const userValue = candidateSkills.find((actual: {text: string, value: string}) => {
						return actual.text === skill.text;
					});
					this.options[index] = [];
					if (skill.type === 'select') {
						skill.values.forEach((value: string) => {
							this.options[index].push({
								id: value.trim(),
								select_value: value.trim()
							});
						});
						const control = new UntypedFormControl(null, validators);
						if (userValue !== undefined) {
							control.setValue(this.fh.keyToSelect(userValue.value, this.options[index]));
						}
						skillEntries.push(this.fb.group({
							element: control,
						}));
					} else if (skill.type === 'multiple' && Array.isArray(skill.values)) {
						const subEntries = this.fb.array([], (skill.mandatory === true) ? [minLengthArray(1)] : []);
						skill.values.forEach((value: string) => {
							value = value.trim();
							if (value) {
								const control = new UntypedFormControl(null);
								subEntries.push(this.fb.group({
									element: control
								}));
								if (userValue !== undefined && userValue.value.indexOf(value) !== -1) {
									control.setValue(true);
								}
							}
						});
						skillEntries.push(subEntries);
					} else {
						const control = new UntypedFormControl(null, validators);
						if (userValue !== undefined) {
							control.setValue(userValue.value);
						}
						skillEntries.push(this.fb.group({
							element: control,
						}));
					}
				});
			}
			if (this.languages !== null && this.languages.length > 0) {
				this.skillsForm.get('languages').setValidators([minLengthArray(1)]);
				let languageEntries: UntypedFormArray;
				languageEntries = this.skillsForm.get('languages') as UntypedFormArray;
				while (languageEntries.length > 0) {
					languageEntries.removeAt(0);
				}
				this.languages.forEach(language => {
					languageEntries.push(this.fb.group({
						element: new UntypedFormControl(candidateLanguages.indexOf(language) !== -1),
					}));
				});
			}
		}
		if (this.showValidation === true) {
			this.fh.validateAllFormFields(this.skillsForm);
		}
	}

	buildDataArrays() {
    this.skills = this.jobtype?.skills || [];
    this.languages = this.jobtype?.languages || [];
	}

	saveSkills() {
		this.set.emit({
			valid: this.skillsForm.valid,
			event: this.event.id,
			skills: this.getSkills(),
			languages: this.getLanguages()
		});
	}

	cancel() {
		this.cancelled.emit(null);
	}

	private getSkills(): Array<string> {
		const skills: Array<any> = [];
		const skillEntries = this.skillsForm.get('skills') as UntypedFormArray;
		skillEntries.controls.forEach((control: AbstractControl, index: number) => {
			const skill = this.skills[index];
			switch (skill.type) {
				case ('multiple'): {
					const values: Array<any> = [];
					const entries = control as UntypedFormArray;
					entries.controls.forEach((subControl: AbstractControl, subIndex: number) => {
						if (subControl.get('element').value === true) {
							values.push(skill.values[subIndex]);
						}
					})
					if (values.length > 0) {
						skills.push({
							text: skill.text,
							value: values
						});
					}
					break;
				}
				case ('select'): {
					if (control.get('element').value) {
						skills.push({
							text: skill.text,
							value: this.fh.selectToValue(control.get('element').value)
						});
					}
					break;
				}
				case ('text'): {
					if (control.get('element').value) {
						skills.push({
							text: skill.text,
							value: control.get('element').value
						});
					}
					break;
				}
				default: {
					if (control.get('element').value === true) {
						skills.push({
							text: skill.text,
							value: true
						});
					}
					break;
				}
			}
		});
		return skills;
	}

	private getLanguages(): Array<string> {
		const values = this.skillsForm.value;
		const languages: Array<any> = [];
		let count = 0;
		this.languages.forEach(language => {
			if (values['languages'].hasOwnProperty(count) && values['languages'][count]['element'] === true) {
				languages.push(language);
			}
			count++;
		});
		return languages;
	}

}
