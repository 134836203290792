<ng-container *transloco="let t;">
<div class="uk-modal-body">
	<div *ngIf="event.status === 'standby' || event.status === 'running'">
		<div class="uk-section">
			<p class="uk-text-center"><span uk-icon="icon: warning; ratio: 3"></span></p>
			<p class="uk-text-center">
				{{ t('partials.preregistration.prereg_day.already_closed') }}
			</p>
		</div>
	</div>
	<div *ngIf="event.status === 'online'">
		<div class="uk-section" *ngIf="registrationLoading === true">
			<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
			<p class="uk-text-center">
				{{ t('partials.preregistration.prereg_day.sending_registration') }}
			</p>
		</div>
		<div class="uk-section form-success" *ngIf="registrationSuccess === true">
			<p class="uk-text-center"><span uk-icon="icon: check; ratio: 3"></span></p>
			<p class="uk-text-center">
				<strong>{{ t('partials.preregistration.prereg_day.thank_you_for_registration') }}</strong>
			</p>
			<div *ngIf="registrationDeepLink !== null" class="uk-text-center">
				<p>
					<strong>{{ t('partials.preregistration.prereg_day.do_you_have_another_minutes_want_to_skip_the_line') }}</strong><br>
				</p>
				<p>
					{{ t('partials.preregistration.prereg_day.complete_your_full_profile', { link: registrationDeepLink }) }}
				</p>
			</div>
		</div>

		<form class="uk-form-stacked" [formGroup]="registerForm" novalidate [hidden]="registrationLoading === true || registrationSuccess === true">

			<div uk-grid class="uk-grid-divider">
				<div class="width-slider" [ngClass]="{'uk-width-2-3@m': registerForm.value.bookslot === 'no', 'uk-width-1-3@m': registerForm.value.bookslot === 'yes'}">
					<sb-forms-input [control]="registerForm.get('firstname')" [label]="t('common.firstname')"></sb-forms-input>
					<sb-forms-input [control]="registerForm.get('lastname')" [label]="t('common.lastname')"></sb-forms-input>
					<sb-forms-input [control]="registerForm.get('email')" [type]="email" [label]="t('common.email')"></sb-forms-input>
					<sb-forms-phone [control]="registerForm.get('mobile')" [label]="t('common.mobile')"></sb-forms-phone>
					<sb-forms-select [control]="registerForm.get('candidate_source')" [options]="optionsCandidateSources" [label]="'How did you hear about Kuehne+Nagel?'"></sb-forms-select>
					<sb-forms-input *ngIf="show_referral" [control]="registerForm.get('candidate_source_referral')" [label]="'Referred by'"></sb-forms-input>
          <ng-container *ngIf="mode === PreregModes.REGISTRATION">
  					<p class="uk-text-small">{{ t('common.privacypolicy') }}</p>
            <p class="uk-text-small"><a href="/downloads/privacypolicy" target="_blank"><span uk-icon="icon: album;"></span> {{ t('partials.preregistration.prereg_day.see_privacy_policy') }}</a></p>
            <sb-forms-checkbox [control]="registerForm.get('privacy')" [label]="t('partials.preregistration.prereg_day.i_accept_terms_and_conditions')" [cbfirst]="true"></sb-forms-checkbox>
          </ng-container>
					<ul class="common-errors">
						<li *ngFor="let message of commonErrors">{{message}}</li>
					</ul>
				</div>
				<div class="width-slider" [ngClass]="{'uk-width-1-3@m': registerForm.value.bookslot === 'no', 'uk-width-2-3@m': registerForm.value.bookslot === 'yes'}">
					<p>
						{{ t('partials.preregistration.prereg_day.schedule_for_interview') }} <br class="uk-hidden@m"> <input style="margin-left: 10px;" type="radio" class="uk-radio" formControlName="bookslot" value="yes"> {{ t('partials.preregistration.prereg_day.yes') }}<input style="margin-left: 10px;" type="radio" class="uk-radio" formControlName="bookslot" value="no"> {{ t('partials.preregistration.prereg_day.no') }}
					</p>
					<app-slotselector *ngIf="registerForm.value.bookslot === 'yes'" [event]="event" (set)="setSlot($event)"></app-slotselector>
				</div>
			</div>
			<input class="uk-input" type="hidden" formControlName="bookslot">
			<input class="uk-input" type="hidden" formControlName="slot">
		</form>
	</div>
</div>
<div class="uk-modal-footer">
	<div uk-grid>
		<div class="uk-width-1-2@m">
			<p class="uk-text-small" *ngIf="!registerForm.valid && registrationSuccess !== true && event.status === 'online'"><span uk-icon="icon: info"></span> {{ t('partials.preregistration.prereg_day.fill_in_necessary_fields') }}</p>
		</div>
		<div class="uk-width-1-2@m uk-text-right">
			<button class="uk-button kn-button uk-modal-close">
				<span [hidden]="registrationSuccess === true">{{ t('common.cancel') }}</span>
				<span [hidden]="registrationSuccess !== true">{{ t('common.close') }}</span>
			</button>&nbsp;
			<button class="uk-button kn-call-to-action" (click)="register()" [disabled]="!registerForm.valid" [hidden]="registrationSuccess === true || event.status !== 'online'">{{ t('partials.preregistration.prereg_day.register') }}</button>
		</div>
	</div>
</div>

</ng-container>
