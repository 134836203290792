import { Component, Input, OnChanges } from '@angular/core';
import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'sb-forms-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent extends CommonComponent implements OnChanges {
  private _options: Array<any>; // tslint:disable-line: variable-name

  @Input() public set options(options: Array<any>) {
    if (options) {
      this._options = options.filter((option: any) => {
        return (
          option.deleted_at === null ||
          option.deleted_at === undefined ||
          option === this.control.value
        );
      });
    } else {
      this._options = [];
    }
  }
  public get options(): Array<any> {
    return this._options;
  }

  @Input() microsite = false;

  ngOnChanges(values: any) {
    if (
      values.options !== undefined &&
      values.options.currentValue !== undefined &&
      values.options.currentValue.length === 1 &&
      this.isRequired() === true
    ) {
      this.control.setValue(values.options.currentValue[0]);
    }
  }

  getViewValue() {
    if (this.blurred === true) {
      return '**********';
    }
    return this.control.value.select_value || null;
  }
}
