import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

@Pipe({
  name: 'sbDate'
})
export class SbDatePipe implements PipeTransform {

  transform(value: any, type: string): string {
    const result = moment(value);
    return (result.isValid()) ? result.format(DATETIMES[type]) : 'invalid date/time';
  }

}
