<ng-container *transloco="let t;">
<ul class="uk-list uk-list-divider uk-text-left">
	<li *ngFor="let value of values" (click)="setActive(value)" [ngClass]="{'active': active.indexOf(value) !== -1}">
		{{value}}
		<div class="circle">
			<span uk-icon="icon:check"></span>
		</div>
	</li>
</ul>

</ng-container>