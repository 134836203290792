import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../../../api/services/api.service';

const MIN_PASSWORD_LENGTH = 8;

@Component({
  selector: 'sb-auth-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  passwordForm: UntypedFormGroup = this.fb.group({
		old: new UntypedFormControl(''),
		new: new UntypedFormControl(''),
		confirm: new UntypedFormControl(''),
	}, {
    validators: [this.passwordValidator]
  });

  submitting = false;

  @Input() email: string = null;
  @Output() changed = new EventEmitter<any>();
  @Output() cancelled = new EventEmitter<void>();


  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService
  ) { }

  ngOnInit(): void {
  }

  public onSubmit(): void {
    this.submitting = true;
    const payload = this.passwordForm.value;
    payload['email'] = this.email;
    this.api.changePassword(payload).subscribe(() => {
      this.changed.emit({
        email: this.email,
        password: payload.new
      });
    }, (error: any) => {
      switch(error.common) {
        case('UserNotFound'): {
          this.cancelled.emit();
          break;
        }
        case('PasswordWrong'): {
          this.passwordForm.get('old').setErrors({passwordWrong: true});
          break;
        }
        case('PasswordLength'): {
          this.passwordForm.get('new').setErrors({passwordLength: true});
          break;
        }
        case('PasswordHistory'): {
          this.passwordForm.get('new').setErrors({passwordHistory: true});
          break;
        }
        case('PasswordSame'): {
          this.passwordForm.get('new').setErrors({passwordSame: true});
          break;
        }
        case('PasswordMatch'): {
          this.passwordForm.get('confirm').setErrors({passwordMatch: true});
          break;
        }
      }
      this.passwordForm.markAllAsTouched();
    }).add(() => {
      this.submitting = false;
    });
  }

  public onCancel(): void {
    this.passwordForm.reset();
    this.cancelled.emit();
  }

  private passwordValidator(group: UntypedFormGroup): void {
    const old = group.get('old')?.value;
    const password = group.get('new')?.value;
    const password2 = group.get('confirm')?.value;
    if (!old) {
      group.get('old').setErrors({ required: true });
    } else {
      group.get('old').setErrors(null);
      group.get('new').setErrors(null);
      group.get('confirm').setErrors(null);
      if (old) {
        if (!password) {
          group.get('new')?.setErrors({ required: true });
        } else {
          const errors = [];
          if (password.length < MIN_PASSWORD_LENGTH) {
            errors.push('passwordLength');
          }
          if (!(/\d/.test(password) && /[A-Z]/.test(password) && /[a-z]/.test(password) && /[^a-zA-Z0-9\s]/.test(password))) {
            errors.push('passwordStrength');
          }
          if (errors.length > 0) {
            const errorList: any = {};
            errors.forEach((error: string) => {
              errorList[error] = true;
            });
            group.get('new')?.setErrors(errorList);
          } else {
            group.get('new')?.setErrors(null);
          }
          if (!password2) {
            group.get('confirm')?.setErrors({ required: true });
          } else if (password !== password2) {
            group.get('confirm')?.setErrors({ passwordMatch: true });
          }
        }
      }
    }
  }
}
