<ng-container *transloco="let t;">
<div class="uk-width-1-1" *ngIf="loading === true">
	<p><span uk-spinner></span>&nbsp;&nbsp;&nbsp;{{ t('partials.skillselector.loading_skill_data') }}</p>
</div>

<div *ngIf="loading === false">
	<form [formGroup]="skillsForm">
		<div uk-grid>
			<div class="uk-width-2-3@m">
				<h3>{{ t('partials.skillselector.skills') }}</h3>
				<div *ngIf="skillsForm.get('skills').controls.length > 0">
					<div formArrayName="skills" *ngFor="let entry of skillsForm.get('skills').controls; let i = index;" class="skill-list-entry">
						<div [formGroupName]="i" class="uk-grid">
							<div class="uk-width-1-2">
								{{skills[i].text}}
							</div>
							<ng-container [ngSwitch]="skills[i].type">
								<ng-container *ngSwitchCase="'yes_no'">
									<div class="uk-width-1-2">
										<sb-forms-checkbox [control]="entry.get('element')"></sb-forms-checkbox>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'text'">
									<div class="uk-width-1-2">
										<sb-forms-textarea [control]="entry.get('element')" [microsite]="true"></sb-forms-textarea>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'select'">
									<div class="uk-width-1-2">
										<sb-forms-select [control]="entry.get('element')" [options]="options[i]" [microsite]="true"></sb-forms-select>
									</div>
								</ng-container>
								<ng-container *ngSwitchCase="'multiple'">
									<div class="uk-width-1-2">
										<ng-container *ngFor="let subEntry of entry.controls; let j = index">
											<sb-forms-checkbox [control]="subEntry.get('element')" [label]="skills[i].values[j]"></sb-forms-checkbox>
										</ng-container>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
			<div class="uk-width-1-3@m">
				<h3>{{ t('partials.skillselector.languages') }}</h3>
				<div *ngIf="skillsForm.get('languages').controls.length > 0">
					<div formArrayName="languages" *ngFor="let entry of skillsForm.get('languages').controls; let i = index;">
						<div [formGroupName]="i">
							<sb-forms-checkbox [control]="entry.get('element')" [label]="languages[i]"></sb-forms-checkbox>
						</div>
					</div>
				</div>
			</div>
		</div>
		<p class="uk-text-right" *ngIf="mode === 'my-event'">
			<button type="button" class="uk-button kn-button" (click)="cancel()">{{ t('common.cancel') }}</button>&nbsp;<button type="submit" class="uk-button kn-call-to-action" (click)="saveSkills()" [disabled]="!skillsForm.valid ? true : null">{{ t('common.save') }}</button>
		</p>
	</form>

</div>

</ng-container>
