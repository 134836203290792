<ng-container *transloco="let t">
  <div
    class="form-field"
    [ngClass]="{
      error: isInvalid(),
      success: isValid(),
      busy: isBusy(),
      readonly: readonly,
      'is-desktop': isDesktop(),
      short: short === true
    }"
  >
    <sb-forms-container
      [label]="label"
      [errors]="control.errors"
      [type]="'timepicker'"
    >
      <div class="input-field uk-inline uk-width-1-1" (click)="setEditable()">
        <input
          *ngIf="readonly === true"
          class="uk-input"
          type="text"
          [value]="getViewValue()"
          [readonly]="readonly"
        />
        <div [hidden]="readonly === true">
          <p-calendar
            [formControl]="control"
            [showTime]="true"
            [timeOnly]="true"
            [hourFormat]="dateTimes.HOUR_FORMAT"
            [minDate]="_min"
            [maxDate]="_max"
            [stepMinute]="stepMinute"
            [showIcon]="true"
            [appendTo]="body"
          ></p-calendar>
        </div>
      </div>
      <div class="input-functions">
        <a
          (click)="resetValue()"
          *ngIf="
            readonly !== true && control.value !== '' && control.value !== null
          "
          ><span uk-icon="icon: close; ratio: 0.8"></span>
          {{ t("sblib.common.clear") }}</a
        >
      </div>
    </sb-forms-container>
  </div>
</ng-container>
