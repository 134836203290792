<ng-container *transloco="let t;">
    <div #offerLetter *ngIf="candidateevent !== null">

        <div class="ol-container">
            <div *ngIf="isAccepted === false && isDeclined === false">
                <sb-ui-offerletter
                    [content]="candidateevent.offerletter_content"
                    (accepted)="accept($event)"
                    (declined)="decline($event)"
                    (printClicked)="print()"
                    #offerletter
                ></sb-ui-offerletter>
            </div>
            <div *ngIf="isAccepted === true" class="uk-text-center accepted">
                <span uk-icon="icon: check; ratio: 3"></span>
                <h1>{{ t('offerletter.hiring_commitment_accepted') }}</h1>
                <p>{{ t('offerletter.thank_you_for_accepting_the_hiring') }}</p>
                <p><a [routerLink]="['/']">{{ t('offerletter.return_home_page') }}</a></p>
            </div>
            <div *ngIf="isDeclined === true" class="uk-text-center declined">
                <span uk-icon="icon: ban; ratio: 3"></span>
                <h1>{{ t('offerletter.commitment_hiring_refused') }}</h1>
                <p>{{ t('offerletter.you_refused_to_be_hired') }}</p>
                <p><a [routerLink]="['/']">{{ t('offerletter.return_home_page') }}</a></p>
            </div>
        </div>
    </div>
</ng-container>
