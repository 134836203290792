import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginGuard } from './guards/login.guard';

import { HomeComponent } from './pages/home/home.component';
import { PassComponent } from './pages/pass/pass.component';
import { SearchComponent } from './pages/search/search.component';
import { MyprofileComponent } from './pages/profile/myprofile/myprofile.component';
import { MyeventsComponent } from './pages/profile/myevents/myevents.component';
import { OfferletterComponent } from './pages/offerletter/offerletter.component';
import { ContentComponent } from './pages/content/content.component';

const routes: Routes = [
	{ path: '', component: HomeComponent },
	{ path: 'search', component: SearchComponent },
  // server contents
	{ path: 'aboutus', component: ContentComponent },
	{ path: 'benefits', component: ContentComponent },
	{ path: 'meetus', component: ContentComponent },
	{ path: 'goodtoknow', component: ContentComponent },
	// guarded
	{ path: 'profile/my-profile', component: MyprofileComponent, canActivate: [LoginGuard] },
	{ path: 'profile/my-events', component: MyeventsComponent, canActivate: [LoginGuard] },
	// misc
	{ path: 'pass/:token/:redirect', component: PassComponent },
	{ path: 'pass/:token', component: PassComponent },
	// offerletter
	{ path: 'sign/:id', component: OfferletterComponent },
	// direct links to events
	{ path: 'loc/:eventid/:marketingsource', component: HomeComponent },
	{ path: 'loc/:eventid', component: HomeComponent },
	{ path: 'private/:eventid', component: HomeComponent },
	{ path: ':eventid/:marketingsource', component: HomeComponent },
	{ path: ':eventid/job/:jobtypeid', component: HomeComponent },
	{ path: ':eventid', component: HomeComponent },
	// rest
	{ path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
