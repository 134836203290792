import { Component, OnInit, Input } from '@angular/core';
import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';

export enum PreregModes {
  REGISTRATION = 'registration',
  DATACOLLECTION = 'datacollection',
}

@Component({
	selector: 'app-preregistration',
	templateUrl: './preregistration.component.html',
	styleUrls: ['./preregistration.component.scss']
})
export class PreregistrationComponent implements OnInit {

	@Input() mode: string = PreregModes.REGISTRATION;
	@Input() event: EventModel = null;
	@Input() candidateevent: CandidateEventModel = null;

	constructor() { }

	ngOnInit() {
	}

}
