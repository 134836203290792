import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EventModel } from 'projects/sb-lib/src/lib/models/event.model';

@Component({
	selector: 'app-slot',
	templateUrl: './slot.component.html',
	styleUrls: ['./slot.component.scss']
})
export class SlotComponent implements OnInit {

	@Input() event: EventModel;
	@Input() data: any;

	@Output() dataChange = new EventEmitter();
	@Output() registerClicked = new EventEmitter();

	constructor(public transloco: TranslocoService) { }

	ngOnInit() {
	}

	setSlot(slot: any) {
		this.data.candidate.slot = slot;
	}

	back() {
		this.data.step = 4;
		this.dataChange.emit(this.data);
	}

	register() {
		this.registerClicked.emit();
	}

}
