import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { parsePhoneNumber, CountryCode, ParseError } from 'libphonenumber-js/min';

import * as _moment from 'moment';
import { COUNTRY_SHORTCUT } from '../../../models/constants';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable({
	providedIn: 'root'
})
export class FormhelperService {

	constructor() { }

	// options

	public constantsToOptions(constantList: any): Array<any> {
		const result = [];
		for (const prop in constantList) {
			if (constantList.hasOwnProperty(prop)) {
				result.push({
					id: prop,
					select_value: constantList[prop]
				});
			}
		}
		return result;
	}

	public modelsToOptions(modelList: Array<any>, selectValueName: string = null): Array<any> {
		const result: Array<any> = [];
		modelList.forEach(model => {
			model.select_value = model[selectValueName];
			result.push(model);
		});
		result.sort((a: any, b: any) => {
			if (a.select_value > b.select_value) {
				return 1;
			} else if (b.select_value > a.select_value) {
				return -1;
			} else {
				return 0;
			}
		});
		return result;
	}

	// selects

	public keyToSelect(key: string, options: Array<any>, propertyToCheck: string = 'id'): any {
		const result = options.find(option => option[propertyToCheck] === key);
		return (result !== undefined) ? result : null;
	}

	public modelToSelect(model: any, options: Array<any>, propertyToCheck: string = 'id'): any {
		if (model === null || model === undefined) {
			return null;
		}
		const result = (Array.isArray(options)) ? options.find(option => option[propertyToCheck] === model[propertyToCheck]) : [];
		return (result !== undefined) ? result : null;
	}

	public selectToValue(selectValue: any, propertyName: string = 'id') {
		if (selectValue !== null && selectValue !== undefined && selectValue.hasOwnProperty(propertyName)) {
			const propertyValue = selectValue[propertyName];
			if (propertyValue !== null && propertyValue !== undefined) {
				return propertyValue;
			}
		}
		return null;
	}

	public multipleToArray(selectValues: any, propertyName: string = 'id') {
		const result: Array<any> = [];
		if (selectValues !== null && selectValues !== undefined && selectValues.length > 0) {
			selectValues.forEach((selectValue: any) => {
				const propertyValue = selectValue[propertyName];
				if (propertyValue !== null && propertyValue !== undefined) {
					result.push(propertyValue);
				}
			});
		}
		return result;
	}

	// date

	public modelToDate(modelValue: any): any {
		return (modelValue !== null && moment(modelValue).isValid()) ? moment(modelValue).toDate() : null;
	}

	public dateToValue(dateValue: any): string {
		return (moment(dateValue).isValid()) ? moment(dateValue).format('YYYY-MM-DD') : null;
	}

	// time

	public modelToTime(modelValue: string, asDate = false, date: any = null): any {
		if (modelValue !== null && modelValue !== undefined) {
			const matches = modelValue.match(/^(\d\d):(\d\d):(\d\d)$/);
			if (matches !== null) {
				const result = (date !== null) ? date.hours(parseInt(matches[1], 10)) : moment().hours(parseInt(matches[1], 10));
				result.minutes(parseInt(matches[2], 10));
				result.seconds(parseInt(matches[3], 10));
				return (asDate === true) ? result.toDate() : result;
			}
		}
		return null;
	}

	public timeToValue(timeValue: any): string {
    const time = moment(timeValue);
		return (time.isValid()) ? time.format('HH:mm') + ':00' : null;
	}

	// phone

	public phoneToValue(phoneValue: any, international = true): string {
		if (phoneValue !== null && phoneValue !== undefined && phoneValue !== '') {
			try {
				const phoneNumber = parsePhoneNumber(phoneValue, COUNTRY_SHORTCUT as CountryCode);
				return (phoneNumber.isValid()) ? ((international === true) ? phoneNumber.format('E.164') : phoneNumber.formatNational()) : phoneValue;
			} catch (error) {
				if (error instanceof ParseError) {
					// console.log(error.message)
				} else {
					throw error;
				}
			}
		}
		return null;
	}

	public valueToPhone(phoneValue: any) {
		if (phoneValue !== null && phoneValue !== undefined && phoneValue !== '') {
			try {
				const phoneNumber = parsePhoneNumber(phoneValue, COUNTRY_SHORTCUT as CountryCode);
				return (phoneNumber.isValid()) ? phoneNumber.format('E.164') : phoneValue;
			} catch (error) {
				if (error instanceof ParseError) {
				} else {
					throw error;
				}
			}
		}
		return null;
	}

	// map model to form
	public modelToForm(model: any, form: UntypedFormGroup, mapping: Array<any>, options: any = null): void {
		if (mapping !== undefined && mapping.length > 0) {
			mapping.forEach((entry: any) => {
				let value = model[entry.prop];
				switch (entry.type) {
					case 'lookup':
						if (options.hasOwnProperty(entry.prop)) {
							value = this.keyToSelect(value, options[entry.prop].list);
						}
						break;
					case 'relation':
						if (options.hasOwnProperty(entry.prop) && options[entry.prop].hasOwnProperty('key')) {
							value = this.modelToSelect(value, options[entry.prop].list, options[entry.prop].key);
						}
						break;
					case 'date':
						value = this.modelToDate(value);
						break;
					case 'time':
						value = this.modelToTime(value);
						break;
					case 'checkbox':
						value = !!(value !== null && value > 0);
						break;
				}
				form.get(entry.field).setValue(value);
			});
		}
	}


	// map form to model

	public formToModel(form: UntypedFormGroup, model: any, mapping: Array<any>): void {
		if (mapping !== undefined && mapping.length > 0) {
			mapping.forEach((entry: any) => {
				const value = form.get(entry.field).value;
				switch (entry.type) {
					case 'input':
						model[entry.prop] = value;
						break;
					case 'lookup':
						model[entry.prop] = this.selectToValue(value);
						break;
					case 'relation':
						model[entry.prop] = value;
						if (model.hasOwnProperty(entry.prop + '_id')) {
							model[entry.prop + '_id'] = this.selectToValue(value);
						}
						break;
					case 'date':
						model[entry.prop] = this.dateToValue(value);
						break;
					case 'time':
						model[entry.prop] = this.timeToValue(value);
						break;
					case 'checkbox':
						model[entry.prop] = (value === true) ? 1 : 0;
						break;
				}
			});
		}
	}

	// validation

	public validateAllFormFields(group: UntypedFormGroup | UntypedFormArray) {
		if (group instanceof UntypedFormGroup) {
			Object.keys(group.controls).forEach(field => {
				const control = group.get(field);
				if (control instanceof UntypedFormControl) {
					control.markAsTouched({ onlySelf: true });
				} else if (control instanceof UntypedFormGroup) {
					this.validateAllFormFields(control);
				} else if (control instanceof UntypedFormArray) {
					this.validateAllFormFields(control);
				}
			});
		} else if (group instanceof UntypedFormArray) {
			const entries = group as UntypedFormArray;
			for (let count = 0; count < entries.length; count++) {
				this.validateAllFormFields(entries.at(count) as UntypedFormArray);
			}
		}
	}

}
