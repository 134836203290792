import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';

import { UiService } from '../../../../services/ui.service';
import { ApiService } from '../../../../services/api.service';

import { CandidateEventModel } from '../../../../../../../sb-lib/src/lib/models/candidateevent.model';
import { SlotModel } from '../../../../../../../sb-lib/src/lib/models/slot.model';

// import * as moment from 'moment-timezone/builds/moment-timezone-with-data';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
	selector: 'app-myevent',
	templateUrl: './myevent.component.html',
	styleUrls: ['./myevent.component.scss']
})
export class MyeventComponent implements OnInit {

	editMode: string = null;
	showValidation = false;
	selectedSlot: SlotModel = null;

	constructor(
		private ui: UiService,
		private api: ApiService,
		private pageScrollService: PageScrollService,
		@Inject(DOCUMENT) private document: any,
    public transloco: TranslocoService
	) { }

	@Input() candidateevent: CandidateEventModel = null;
	@Output() unregistered = new EventEmitter<CandidateEventModel>();

	ngOnInit() {
	}

	showDetails() {

	}

	isEditable() {
		let slot: any = null;
		if (this.candidateevent.slot !== null) {
			slot = moment.tz(this.candidateevent.slot.slot_time, this.candidateevent.event.timezone);
		}
		const now = moment().tz(this.candidateevent.event.timezone);
		return this.candidateevent.event.status !== 'finished' && this.candidateevent.event.status !== 'archived' &&
			((this.candidateevent.event.is_candidate_day === true && this.candidateevent.event.status === 'online') ||
			(this.candidateevent.event.is_candidate_day !== true && (this.candidateevent.slot === null || (slot !== null && slot.subtract(30, 'minutes').isAfter(now)))));
	}

	changeJob(showValidation: boolean = false) {
		this.editMode = 'job';
		this.showValidation = showValidation;
		this.pageScrollService.scroll({
			document: this.document,
			scrollTarget: '.job-selector',
		});
	}

	saveJob(payload: any) {
		this.api.setJob(payload).subscribe(
			candidateevent => {
				this.editMode = null;
				if (payload.jobtype !== this.candidateevent.jobtype?.id) {
					this.editSkills();
				}
				this.candidateevent = candidateevent;
			},
			error => { }
		);
	}

	editSkills(showValidation: boolean = false) {
		this.editMode = 'skills';
		this.showValidation = showValidation;
		this.pageScrollService.scroll({
			document: this.document,
			scrollTarget: '.skill-selector',
		});
	}

	saveSkills(payload: any) {
		this.api.setSkills(payload).subscribe(
			candidateevent => {
				this.candidateevent = candidateevent;
				this.editMode = null;
			},
			error => { }
		);
	}

	changeSlot() {
		this.editMode = 'slot';
		this.pageScrollService.scroll({
			document: this.document,
			scrollTarget: '.slot-selector',
		});
	}

	deleteSlot() {
		this.ui.showConfirm(this.transloco.translate('profile.myevents.myevent.really_cancel_interview'), () => {
			this.api.deleteSlot(this.candidateevent.event.id).subscribe(
				candidateevent => {
					this.candidateevent = candidateevent;
				},
				error => { }
			);
		});
	}

	setSlot(slot: SlotModel) {
		this.selectedSlot = slot;
	}

	saveSlot() {
		const payload = {
			event: this.candidateevent.event.id,
			slot: this.selectedSlot
		};
		this.api.bookSlot(payload).subscribe(
			candidateevent => {
				this.candidateevent = candidateevent;
				this.editMode = null;
			},
			error => { }
		);
	}

	cancelForm() {
		this.editMode = null;
	}

	unregister() {
		this.unregistered.emit(this.candidateevent);
	}

}
