<ng-container *transloco="let t;">
	<ul class="uk-navbar-nav" *ngIf="isLoggedIn !== true">
		<li class="uk-offcanvas-close"><a (click)="showLogin()"><span uk-icon="icon: user" style="margin-right:10px;"></span> {{ t('partials.layout.login.login') }}</a></li>
	</ul>
	<ul class="uk-navbar-nav" *ngIf="isLoggedIn === true">
		<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/profile/my-profile"><span uk-icon="icon: user" style="margin-right:10px;"></span>  {{ t('partials.layout.login.my_profile') }}</a></li>
		<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/profile/my-events"><span uk-icon="icon: calendar" style="margin-right:10px;"></span>  {{ t('partials.layout.login.my_events') }}</a></li>
		<li class="uk-offcanvas-close"><a (click)="showLogout()">{{ t('partials.layout.login.logout') }}</a></li>
	</ul>

	<div id="loginModal" uk-modal>
		<div class="uk-modal-dialog">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div class="uk-modal-header">
				<h2 class="uk-modal-title">{{ t('partials.layout.login.login') }}</h2>
			</div>
			<form class="uk-form-stacked" [formGroup]="loginForm" novalidate (submit)="doLogin()">
				<div class="uk-modal-body">
					<app-messagelist [group]="'login'"></app-messagelist>
					<div [class.inputs-readonly]="isPrivacyMode === true">
						<sb-forms-input [control]="loginForm.get('lastname')" [label]="t('partials.layout.login.your_name')" [readonly]="isPrivacyMode === true"></sb-forms-input>
						<sb-forms-input [control]="loginForm.get('accesscode')" [label]="t('partials.layout.login.your_confirmation_code')" [readonly]="isPrivacyMode === true"></sb-forms-input>
					</div>
					<div *ngIf="isPrivacyMode === true">
						<p class="uk-text-small">{{ t('common.privacypolicy') }}</p>
						<p class="uk-text-small"><a href="/downloads/privacypolicy" target="_blank"><span uk-icon="icon: album;"></span> {{ t('partials.layout.login.see_privacy_policy') }}</a></p>
						<sb-forms-checkbox [control]="loginForm.get('privacy')" [label]="t('partials.layout.login.i_accept_terms_and_conditions')" [cbfirst]="true"></sb-forms-checkbox>
					</div>
					<p *ngIf="isPrivacyMode !== true">{{ t('partials.layout.login.forgot_confirmation_code') }} <a (click)="showPasswordReset()">{{ t('partials.layout.login.reenter_here') }}</a></p>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<a class="uk-button kn-button uk-modal-close">{{ t('common.cancel') }}</a>&nbsp;<button type="submit" class="uk-button kn-call-to-action" [disabled]="!loginForm.valid">{{ t('partials.layout.login.login') }}</button>
				</div>
			</form>
		</div>
	</div>

	<div id="passwordResetModal" uk-modal>
		<div class="uk-modal-dialog">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div class="uk-modal-header">
				<h2 class="uk-modal-title">{{ t('partials.layout.login.reset_confirmation_code') }}</h2>
			</div>

			<div class="uk-modal-body" *ngIf="passwordResetLoading === true">
				<div class="uk-section">
					<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
					<p class="uk-text-center">
						{{ t('partials.layout.login.request_confirmation_code') }}
					</p>
				</div>
			</div>
			<div class="uk-modal-body" *ngIf="passwordResetSuccess === true">
				<div class="uk-section form-success">
					<p class="uk-text-center"><span uk-icon="icon: check; ratio: 3"></span></p>
					<p class="uk-text-center">
						<strong>{{ t('partials.layout.login.confirmation_code_successful') }}</strong>
					</p>
					<p class="uk-text-center">
						{{ t('partials.layout.login.email_will_be_send') }}
					</p>
				</div>
			</div>
			<div class="uk-modal-footer uk-text-right" *ngIf="passwordResetSuccess === true">
				<a class="uk-button kn-button uk-modal-close">{{ t('common.close') }}</a>&nbsp;<button type="button" class="uk-button kn-call-to-action" (click)="showLogin()">{{ t('partials.layout.login.back_to_login') }}</button>
			</div>

			<form class="uk-form-stacked" [formGroup]="passwordResetForm" novalidate (submit)="doResetPassword()" [hidden]="passwordResetLoading === true || passwordResetSuccess === true">
				<div class="uk-modal-body">
					<app-messagelist [group]="'passwordreset'"></app-messagelist>
					<sb-forms-input [control]="passwordResetForm.get('lastname')" [label]="t('partials.layout.login.your_name')"></sb-forms-input>
					<sb-forms-input [control]="passwordResetForm.get('email')" [label]="t('partials.layout.login.your_e_mail')"></sb-forms-input>
				</div>
				<div class="uk-modal-footer uk-text-right">
					<a class="uk-button kn-button uk-modal-close">{{ t('common.cancel') }}</a>&nbsp;<button type="submit" class="uk-button kn-call-to-action" [disabled]="!passwordResetForm.valid">{{ t('partials.layout.login.reset') }}</button>
				</div>
			</form>
		</div>
	</div>


	<div id="logoutModal" uk-modal>
		<div class="uk-modal-dialog">
			<button class="uk-modal-close-default" type="button" uk-close></button>
			<div class="uk-modal-header">
				<h2 class="uk-modal-title">{{ t('partials.layout.login.logout') }}</h2>
			</div>
			<div class="uk-modal-body">
				{{ t('partials.layout.login.logout_from_website') }}
			</div>
			<div class="uk-modal-footer uk-text-right">
				<button class="uk-button kn-button uk-modal-close">{{ t('partials.layout.login.no') }}</button>&nbsp;<button class="uk-button kn-call-to-action" (click)="doLogout()">{{ t('partials.layout.login.yes') }}</button>
			</div>
		</div>
	</div>

</ng-container>
