import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, AbstractControl } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { formatCurrency } from '@angular/common';
import { FormhelperService } from '../../services/formhelper.service';
import { AppCoreService } from '../../../core/services/app-core.service';

@Component({
	selector: 'sb-forms-common',
	templateUrl: './common.component.html',
	styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit, OnChanges {

	constructor(
		protected deviceService: DeviceDetectorService,
		protected app: AppCoreService,
		public fh: FormhelperService
	) {}

	@Input() control: UntypedFormControl;
	@Input() type = 'text';
	@Input() label: string;
	@Input() placeholder: string = '';
	@Input() readonly: boolean;
	@Input() busy: boolean;
	@Input() arrayIndex: number;
	@Input() validMode = 'touched';
	@Input() prefix: string = null;
	@Input() short = false;
	@Input() blurred = false;
	@Input() showClipboard = false;
	@Input() microsite = false;

	@Output() changed = new EventEmitter<any>();
	@Output() keypressed = new EventEmitter<any>();
	@Output() editClicked = new EventEmitter<void>();

	@ViewChild('input') nativeInput: ElementRef;

	ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.readonly) {
      if (changes.readonly.currentValue === true) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

	resetValue() {
		this.control?.patchValue('');
		this.control?.markAsTouched();
	}

	isInvalid(): boolean {
		if (this.validMode === 'touched') {
			return (
				this.readonly !== true &&
        this.control &&
				this.control.invalid &&
				this.control.touched
			);
    } else if (this.validMode === 'always') {
      return this.control.invalid;
		} else {
			return this.readonly !== true && this.control.invalid;
		}
	}

	isValid(): boolean {
		return (
			this.readonly !== true &&
      this.control &&
			!this.control.invalid &&
			this.control.value !== '' &&
			this.control.value !== null &&
			this.control.value !== undefined &&
			this.control.value !== false
		);
	}

	isBusy(): boolean {
		return this.busy === true;
	}

	hasErrors(): boolean { // @deprecated
		return this.control?.errors !== null;
	}

	setEditable() {
		if (this.isDesktop()) {
			this.editClicked.emit();
		}
	}

	onChanged() {
    if (this.readonly !== true) {
      this.changed.emit(this.control?.value);
    }
	}

	onKeypressed() {
    if (this.readonly !== true) {
      this.keypressed.emit(this.control?.value);
    }
	}

	isDesktop(): boolean {
		return this.app.isDesktop();
	}

	isRequired(): boolean {
		if (this.control.validator) {
			const validator = this.control.validator({} as AbstractControl);
			if (validator && validator.required) {
				return true;
			}
		}
		return false;
	}

	getViewValue() {
		if (this.blurred === true) {
			return '**********';
		}
		switch (this.type) {
			case ('currency'): {
				return (this.control.value === -1 || ('' + this.control.value).toLowerCase() === 'n/a') ? 'n/a' : formatCurrency(this.control.value, 'en-GB', '') || null;
				break;
			}
		}
		return this.control.value || null;
	}

	copyToClipboard() {
		if (this.nativeInput !== undefined) {
			this.nativeInput.nativeElement.select();
			document.execCommand('copy');
			this.nativeInput.nativeElement.setSelectionRange(0, 0);
		}
	}

}
