<ng-container *transloco="let t">
  <div
    class="uk-flex uk-flex uk-flex-middle"
    [ngClass]="{
      active: active === true,
      preregistered: candidateevent.idnumber === null
    }"
    *ngIf="candidateevent !== null && candidateevent.candidate !== null"
  >
    <div class="card-avatar">
      <div
        *ngIf="candidateevent.candidate.avatar_image === null"
        class="no-avatar avatar-small"
      ></div>
      <img
        *ngIf="candidateevent.candidate.avatar_image !== null"
        [src]="candidateevent.candidate.avatar_image"
        class="avatar avatar-small"
      />
      <div class="status" *ngIf="candidateevent.interview_end !== null">
        <button
          class="hired"
          *ngIf="candidateevent.interview_result === 'hired'"
        >
          <span uk-icon="icon: check"></span>
        </button>
        <button
          class="pooled"
          *ngIf="candidateevent.interview_result === 'pooled'"
        >
          <span uk-icon="icon: database"></span>
        </button>
        <button
          class="rejected"
          *ngIf="candidateevent.interview_result === 'rejected'"
        >
          <span uk-icon="icon: close"></span>
        </button>
        <button
          class="declined"
          *ngIf="candidateevent.interview_result === 'declined'"
        >
          <span uk-icon="icon: reply"></span>
        </button>
      </div>
      <div class="status" *ngIf="candidateevent.interview_end === null">
        <button class="interview" *ngIf="candidateevent.status === 'interview'">
          <span uk-icon="icon: microphone"></span>
        </button>
        <button class="noshow" *ngIf="candidateevent.status === 'noshow'">
          <span uk-icon="icon: ban"></span>
        </button>
      </div>
      <div class="status" *ngIf="candidateevent.interview_start === null">
        <button
          class="zoom"
          *ngIf="
            candidateevent.misc !== null &&
            candidateevent.misc['zoom']['status'] === 'started'
          "
        >
          <span uk-icon="icon: video-camera"></span>
        </button>
      </div>
      <div class="zerberus" *ngIf="candidateevent.candidate.zerberus_status === null" [title]="t('sblib.ui.card.zerberus_status_pending')"><fa-icon [icon]="faShield"></fa-icon></div>
      <div class="zerberus" *ngIf="candidateevent.candidate.zerberus_status !== null" [ngClass]="candidateevent.candidate.zerberus_status" [title]="t('sblib.ui.card.zerberus_status') + ': ' + candidateevent.candidate.zerberus_status"><fa-icon [icon]="faShield"></fa-icon></div>
    </div>
    <div class="card-content">
      <div *ngIf="candidateevent.event.type !== 'candidate-friday'">
        <!-- @todo: auf getter (is_candidate_friday) umbauen -->
        <p class="title" *ngIf="candidateevent.idnumber !== null">
          #{{ candidateevent.idnumber }}
        </p>
        <p class="title" *ngIf="candidateevent.idnumber === null">
          #{{ t('sblib.ui.card.preregistered') }}
        </p>
      </div>
      <p class="content">
        {{ candidateevent.candidate.firstname }}<br />{{
          candidateevent.candidate.lastname
        }}
      </p>
    </div>
    <div class="active-background"></div>
  </div>

  <div
    class="uk-flex uk-flex uk-flex-middle"
    [ngClass]="{ active: active === true }"
    *ngIf="candidateevent === null && slotline === null"
  >
    <div class="card-avatar">
      <div class="no-avatar avatar-small"></div>
    </div>
    <div class="card-content">
      <p class="content" [innerHtml]="t('sblib.ui.card.no_candidate')"></p>
    </div>
    <div class="active-background"></div>
  </div>
</ng-container>
