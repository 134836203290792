import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { UserloginComponent } from './components/userlogin/userlogin.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { SbFormsModule } from '../forms/forms.module';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { SbDatePipe } from './pipes/sb-date/sb-date.pipe';

@NgModule({
	declarations: [UserloginComponent, ChangePasswordComponent, SbDatePipe],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		SbFormsModule,
    TranslocoModule,
    ButtonModule
	],
	exports: [
		UserloginComponent,
    ChangePasswordComponent,
    SbDatePipe
	]
})
export class SbCoreModule { }
