import { Deserializable } from './deserializable.model';

export class CustomerModel implements Deserializable {
	// columns
	id: string;
	name: string;
	description: string;
	logo_image: string;
	header_image: string;
	image_video: string;

	deserialize(input: any): this {
		return Object.assign(this, input);
	}

}
