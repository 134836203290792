<ng-container *transloco="let t;">
<div class="uk-card uk-card-hover uk-card-default uk-card-small uk-margin-bottom">
	<div class="uk-card-header">
		<p class="uk-card-title"><a name="event{{candidateevent.event.id}}"></a>{{candidateevent.event.name}}</p>
	</div>
	<div class="uk-card-body">

		<div uk-grid>
			<div class="uk-width-expand">
				<span uk-icon="icon: calendar"></span> {{candidateevent.event.date_start | sbDate: 'DATE_LONG' }}<span *ngIf="candidateevent.event.date_start !== candidateevent.event.date_end"> - {{candidateevent.event.date_end | sbDate: 'DATE_LONG' }}</span><br>
				<span *ngIf="candidateevent.event.virtual !== 1" uk-icon="icon: location"></span> {{candidateevent.event.address_city}}
			</div>
			<div class="uk-width-auto uk-text-center uk-text-small" *ngIf="isEditable()">
				<!-- <sb-ui-circular [current]="candidateevent.getProfileStatus()" [radius]="35" [text]="candidateevent.getProfileStatus() + '%'"></sb-ui-circular> -->
				<sb-ui-circular [current]="candidateevent.profile_status.full.amount" [radius]="35" [text]="candidateevent.profile_status.full.amount + '%'"></sb-ui-circular><br>
				<span *ngIf="candidateevent.profile_status.profile.amount &lt; 99"><a [routerLink]="['/profile/my-profile']" [queryParams]="{mode: 'complete'}">{{ t('profile.myevents.myevent.complete_your_profile') }}</a><br></span>
				<span *ngIf="candidateevent.profile_status.job.amount &lt; 99"><a (click)="changeJob(true)">{{ t('profile.myevents.myevent.complete_job') }}</a><br></span>
				<span *ngIf="candidateevent.jobtype !== null && candidateevent.profile_status.skills.amount &lt; 99"><a (click)="editSkills(true)">{{ t('profile.myevents.myevent.complete_your_skills') }}</a></span>
			</div>
		</div>
		<p>{{candidateevent.event.teasertext}}</p>

		<hr>
		<div uk-grid class="uk-child-width-1-3@m">
			<div>
				<div>
					<p><strong>{{ t('profile.myevents.myevent.your_position') }}</strong></p>
					<div *ngIf="candidateevent.jobtype !== null">
						<p>
							<strong>{{candidateevent.jobtype.title}}</strong><br>
							<span *ngIf="candidateevent.joblocation">{{ t('common.location') }}: {{candidateevent.joblocation.address_city}}<br></span>
							<span *ngIf="candidateevent.job">{{ t('profile.myevents.myevent.starts_at') }}: {{candidateevent.job.date_start | sbDate: 'DATE_MONTH_YEAR'}}<br></span>
						</p>
						<p>
							<a *ngIf="editMode === null && isEditable()" (click)="changeJob()" class="sb-function"><span uk-icon="icon: pencil"></span> {{ t('profile.myevents.myevent.change_job') }}</a>&nbsp;
						</p>
					</div>
					<div *ngIf="candidateevent.jobtype === null">
						<p>
              {{ t('profile.myevents.myevent.no_job_selected') }}
						</p>
						<p>
							<a *ngIf="editMode === null && isEditable()" (click)="changeJob()" class="sb-function"><span uk-icon="icon: pencil"></span> {{ t('profile.myevents.myevent.select_job') }}</a>&nbsp;
						</p>
					</div>
				</div>
			</div>
			<div>
				<div>
					<p><strong>{{ t('profile.myevents.myevent.your_competences') }}</strong></p>
					<div *ngIf="candidateevent.jobtype !== null">
						<p>
							<span *ngIf="candidateevent?.interview_data?.candidateData?.competences?.length > 0">{{candidateevent?.interview_data?.candidateData?.competences?.length}} {{ t('profile.myevents.myevent.select_competences') }}<br></span>
							<span *ngIf="candidateevent?.interview_data?.candidateData?.languages?.length > 0">{{candidateevent?.interview_data?.candidateData?.languages?.length}} {{ t('profile.myevents.myevent.select_languages') }}<br></span>
						</p>
						<p>
							<a *ngIf="editMode === null && isEditable()" (click)="editSkills()" class="sb-function"><span uk-icon="icon: pencil"></span> {{ t('profile.myevents.myevent.edit_competences') }}</a>&nbsp;
						</p>
					</div>
					<div *ngIf="candidateevent.jobtype === null">
						<p *ngIf="isEditable()">
              {{ t('profile.myevents.myevent.select_job_first') }}
						</p>
						<p *ngIf="!isEditable()">
              {{ t('profile.myevents.myevent.no_skill_selected') }}
						</p>
					</div>
				</div>
			</div>
			<div>
				<p><strong>{{ t('profile.myevents.myevent.your_interview') }}</strong></p>
					<div *ngIf="candidateevent.slot === null">
						<div *ngIf="candidateevent.can_book_interview === false">
							<span *ngIf="candidateevent.getProfileStatus() &gt;= 99">{{ t('profile.myevents.myevent.do_before_book_interview') }}</span>
							<span *ngIf="candidateevent.getProfileStatus() &lt; 99">{{ t('profile.myevents.myevent.please_complete') }} <a [routerLink]="['/profile/my-profile']" [queryParams]="{mode: 'complete'}">{{ t('profile.myevents.myevent.profil') }}</a> {{ t('profile.myevents.myevent.to_book_interview') }}</span>
						</div>
						<div *ngIf="candidateevent.can_book_interview === true">
							<p>
								{{ t('profile.myevents.myevent.not_yet_booked') }}
							</p>
							<p *ngIf="editMode === null && isEditable()">
								<a (click)="changeSlot()" class="sb-function"><span uk-icon="icon: comment"></span> {{ t('profile.myevents.myevent.book_interview') }}</a>
							</p>
						</div>
					</div>
					<div *ngIf="candidateevent.slot !== null">
						<p>
							{{ t('profile.myevents.myevent.booked_for') }}<br>
							<strong>{{candidateevent.slot.slot_time | sbDate: 'DATETIME_LONG'}}</strong>
						</p>
						<p *ngIf="editMode === null && isEditable()">
							<a (click)="changeSlot()" class="sb-function"><span uk-icon="icon: comment"></span> {{ t('common.edit') }}</a>
							| <a (click)="deleteSlot()" class="sb-function"><span uk-icon="icon: trash"></span> {{ t('common.cancel') }}</a>
						</p>
						<p *ngIf="candidateevent.zoom_candidate !== null">
							<a [href]="candidateevent.zoom_candidate" target="_blank" class="sb-function"><span uk-icon="icon: video-camera"></span> {{ t('profile.myevents.myevent.join_with_zoom') }}</a>
						</p>
					</div>
				</div>
			</div>
		<div class="slot-selector">
			<div *ngIf="editMode === 'slot' && isEditable()">
				<hr>
				<app-slotselector [event]="candidateevent.event" [day]="candidateevent?.slot?.eventday" [slot]="candidateevent?.slot?.slot_time" (set)="setSlot($event)"></app-slotselector>
				<p class="uk-text-right">
					<button type="button" class="uk-button kn-button" (click)="cancelForm()">{{ t('common.cancel') }}</button>&nbsp;<button type="submit" class="uk-button kn-call-to-action" (click)="saveSlot()">{{ t('common.save') }}</button>
				</p>
			</div>
		</div>
		<div class="job-selector">
			<div *ngIf="editMode === 'job' && isEditable()">
				<hr>
				<h2>{{ t('profile.myevents.myevent.your_position') }}</h2>
				<app-jobselector [candidateevent]="candidateevent" [event]="candidateevent.event" [showValidation]="showValidation" (set)="saveJob($event)" (cancelled)="cancelForm()"></app-jobselector>
			</div>
		</div>
		<div class="skill-selector">
			<div *ngIf="editMode === 'skills' && isEditable()">
				<hr>
				<h2>{{ t('profile.myevents.myevent.complementing_skills') }}</h2>
				<app-skillselector [candidateevent]="candidateevent" [event]="candidateevent.event" [showValidation]="showValidation" (set)="saveSkills($event)" (cancelled)="cancelForm()"></app-skillselector>
			</div>
		</div>

	</div>
	<div class="uk-card-footer">
		<a *ngIf="editMode === null && isEditable()" (click)="unregister()" class="sb-function"><span uk-icon="icon: trash"></span> {{ t('profile.myevents.myevent.unregister_from_event') }}</a>
	</div>
</div>

</ng-container>
