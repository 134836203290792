<ng-container *transloco="let t;">
    <div class="uk-container">
        <div class="uk-section">

            <app-loader [mode]="loadingMode" [messageLoading]="t('common.loading')" [messageError]="t('pass.profile_not_loaded')"></app-loader>

            <form *ngIf="isPrivacyMode === true && loadingMode === null" class="uk-form-stacked" [formGroup]="privacyForm" novalidate (submit)="onPrivacySubmit()">
                <p class="uk-text-small">{{ t('common.privacypolicy') }}</p>
                <p class="uk-text-small"><a href="/downloads/privacypolicy" target="_blank"><span uk-icon="icon: album;"></span> {{ t('pass.reference_policy_document') }}</a></p>
                <sb-forms-checkbox [control]="privacyForm.get('privacy')" [label]="t('pass.accept_terms_conditions')" [cbfirst]="true"></sb-forms-checkbox>
                <div class="uk-text-right">
                    <a class="uk-button kn-button" (click)="onCancel()">{{ t('common.cancel') }}</a>&nbsp;<button type="submit" class="uk-button kn-call-to-action" [disabled]="!privacyForm.valid">{{ t('pass.proceed') }}</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>
