<div class="form-field" [ngClass]="{'error': isInvalid(), 'success': isValid(), 'busy': isBusy(), 'readonly': readonly, 'is-desktop': isDesktop(), 'short': short === true, 'microsite': microsite === true}">
	<sb-forms-container [label]="label" [errors]="control.errors" [type]="type" [microsite]="microsite">
		<div class="input-field uk-inline uk-width-1-1" (click)="setEditable()">
			<span class="uk-form-icon uk-form-icon-flip" *ngIf="prefix !== null">{{prefix}}</span>
			<input *ngIf="readonly === true" class="uk-input" [type]="(blurred === true) ? 'password' : type" [readonly]="readonly" [value]="getViewValue()" #input>
			<input *ngIf="readonly !== true" class="uk-input" [type]="type" [formControl]="control" [readonly]="readonly" (change)="onChanged()" (keyup)="onKeypressed()" #input>
		</div>
		<div class="input-functions">
			<div *ngIf="showClipboard === true" title="Copy to Clipboard"><a (click)="copyToClipboard()"><span uk-icon="icon: move"></span> Copy</a></div>
		</div>
	</sb-forms-container>
</div>
