import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input } from '@angular/core';
import { PageMessage } from '../../types/pagemessage';
import { UiService } from '../../services/ui.service';

@Component({
	selector: 'app-messagelist',
	templateUrl: './messagelist.component.html',
	styleUrls: ['./messagelist.component.scss']
})
export class MessagelistComponent implements OnInit {

	messageList: PageMessage;

	constructor(
		private ui: UiService
	, public transloco: TranslocoService) { }

	@Input() group: string = null;

	ngOnInit() {
		this.ui.getMessageList().subscribe(messageList => this.messageList = messageList);
	}

}
