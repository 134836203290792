import { Deserializable } from './deserializable.model';

import { EventModel } from './event.model';
import { SlotlineModel } from './slotline.model';
import { SlotModel } from './slot.model';

export class EventdayModel implements Deserializable {
	// db columns
	id: string;
	event_id: string;
	status: string;
	date_start: string;
	time_start: string;
	time_end: string;
	time_offset: number;
	slots_times: any;
	email_upcoming: string;
	email_tomorrow: string;
	email_today: string;
	// relations parent
	event: EventModel;
	// relations childs
	slotlines: SlotlineModel[];
	slots: SlotModel[];

	deserialize(input: any): this {
		Object.assign(this, input);
		// customer
		if (this.event !== null && this.event !== undefined) {
			this.event = new EventModel().deserialize(this.event);
		}
		// jobtypes
		let slotlines: SlotlineModel[] = null;
		if (this.slotlines !== null && this.slotlines !== undefined) {
			slotlines = [];
			this.slotlines.forEach((slotline: SlotlineModel) => {
				slotlines.push(new SlotlineModel().deserialize(slotline));
			});
		}
		this.slotlines = slotlines;
		// slots
		let slots: SlotModel[] = null;
		if (this.slots !== null && this.slots !== undefined) {
			slots = [];
			this.slots.forEach((slot: SlotModel) => {
				slots.push(new SlotModel().deserialize(slot));
			});
		}
		this.slots = slots;
		return this;
	}

	// properties

	// methods

  public hasSlotsTimes(): boolean {
    return this.slots_times?.length > 0
  }


}

