<ng-container *transloco="let t">
  <div *ngIf="data === null || data.length === 0">
    <sb-ui-emptyhint
      [message]="t('sblib.ui.datalist.no_data')"
    ></sb-ui-emptyhint>
  </div>

  <div *ngIf="data !== null && data.length > 0">
    <table class="uk-table uk-table-divider uk-table-small">
      <thead>
        <tr>
          <th *ngFor="let column of mapping">
            {{ column.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entry of data">
          <td
            *ngFor="let column of mapping"
            [style.color]="entry[column.color]"
          >
            <span
              *ngIf="column.lookup === undefined || column.lookup === null"
              [innerHTML]="entry[column.data]"
            ></span>
            <span
              *ngIf="column.lookup !== undefined && column.lookup !== null"
              >{{ column.lookup[entry[column.data]] }}</span
            >
          </td>
          <td class="uk-text-right">
            <a
              (click)="onEditClicked(entry)"
              [ngClass]="{ disabled: entry.is_editable === false }"
              ><span uk-icon="icon: pencil;"></span
            ></a>
            |
            <a
              (click)="onDeleteClicked(entry)"
              [ngClass]="{ disabled: entry.deletable === false }"
              ><span uk-icon="icon: trash;"></span
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>
