import { Component, Input } from '@angular/core';
import { CommonComponent } from "../common/common.component";

@Component({
  selector: 'sb-forms-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent extends CommonComponent {

	@Input() options: Array<any>;
	@Input() size: number = 3;

	getViewValue() {
		if (this.blurred === true) {
			return '**********';
		}
		return this.control.value.select_value || null;
	}
}
