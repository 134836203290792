import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

import * as moment from 'moment-timezone';

import { VALIDATOR_EEO } from '../../../../services/data.service';
import { EventModel } from '../../../../../../../sb-lib/src/lib/models/event.model';
import { ApiService } from '../../../../services/api.service';
import { FormhelperService } from '../../../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';

const MAXYEARS = 60;

@Component({
	selector: 'app-disability',
	templateUrl: './disability.component.html',
	styleUrls: ['./disability.component.scss']
})
export class DisabilityComponent implements OnInit {

	@Input() event: EventModel;
	@Input() data: any;

	@Output() dataChange = new EventEmitter();

	form: FormGroup = new FormGroup({});
	options: any = {};
	min: any = null;
	max: any = null;

	constructor(
		private api: ApiService,
		private fb: FormBuilder,
		private fh: FormhelperService
	) { }

	ngOnInit() {
		this.api.getEeoData().subscribe((eeo: any) => {
			const data: any = {};
			for (const key in eeo) {
				if (!eeo.hasOwnProperty(key)) { continue; }
				data[key] = [];
				const options = eeo[key];
				options.forEach((option: any) => {
					data[key].push({
						key: option,
						select_value: option,
					});
				});
			}
			this.options = data;
		});
		this.max = moment();
		this.min = moment().subtract(MAXYEARS, 'years');
		this.form = this.fb.group({
			eeo1: new FormControl('', VALIDATOR_EEO),
			eeo2: new FormControl('', VALIDATOR_EEO),
			eeo3: new FormControl('', VALIDATOR_EEO),
			eeo4: new FormControl(''),
			eeo5: new FormControl('', VALIDATOR_EEO),
			eeo6: new FormControl('', VALIDATOR_EEO),
		});	}

	back() {
		this.setData(2);
		this.dataChange.emit(this.data);
	}

	next() {
		if (this.form.valid) {
			this.setData(4);
			this.dataChange.emit(this.data);
		} else {
			this.fh.validateAllFormFields(this.form);
		}
	}

	private setData(step: any) {
		this.data.step = step;
		this.data.candidate.eeo = {
			Disability: (typeof this.form.get('eeo1').value === 'object' && this.form.get('eeo1').value !== null) ? this.form.get('eeo1').value['key'] : null,
			'Disabled Veteran': (typeof this.form.get('eeo2').value === 'object' && this.form.get('eeo2').value !== null) ? this.form.get('eeo2').value['key'] : null,
			Gender: (typeof this.form.get('eeo3').value === 'object' && this.form.get('eeo3').value !== null) ? this.form.get('eeo3').value['key'] : null,
			'Military Discharge Date': (this.form.get('eeo4').value !== undefined && moment(this.form.get('eeo4').value).isValid()) ? moment(this.form.get('eeo4').value).format('YYYY-MM-DD') : null,
			'Military Status': (typeof this.form.get('eeo5').value === 'object' && this.form.get('eeo5').value !== null) ? this.form.get('eeo5').value['key'] : null,
			'Race/Ethnicity': (typeof this.form.get('eeo6').value === 'object' && this.form.get('eeo6').value !== null) ? this.form.get('eeo6').value['key'] : null,
		};
	}
}
