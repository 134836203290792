<ng-container *transloco="let t;">
<div id="offCanvasNav" uk-offcanvas>
    <div class="uk-offcanvas-bar">
		<button class="uk-offcanvas-close" type="button" uk-close></button>
        <ul class="uk-nav uk-nav-default">
			<li class="uk-offcanvas-close" routerLinkActive="uk-active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/">{{ t('partials.layout.offcanvas.home') }}</a></li>
			<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/aboutus">{{ t('partials.layout.offcanvas.aboutus') }}</a></li>
			<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/search">{{ t('partials.layout.offcanvas.search') }}</a></li>
			<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/candidatevoices">{{ t('partials.layout.offcanvas.meet_us') }}</a></li>
			<li class="uk-offcanvas-close" routerLinkActive="uk-active"><a routerLink="/goodtoknow">{{ t('partials.layout.offcanvas.goodtoknow') }}</a></li>
		</ul>
		<hr>
		<app-login class="off-canvas"></app-login>
	</div>
</div>

</ng-container>
