import { PreregInitDataType } from './../preregistration/prereg-friday/personal-data/personal-data.component';
import { PreregModes } from './../preregistration/preregistration.component';
import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

declare var UIkit: any;

@Component({
  selector: 'app-collect-eventdata',
  templateUrl: './collect-eventdata.component.html',
  styleUrls: ['./collect-eventdata.component.scss']
})
export class CollectEventdataComponent implements OnInit {

  @Output() collected = new EventEmitter<any>();

  candidateevent: CandidateEventModel = null;
  modal: any = null;
  init: PreregInitDataType;

  PreregModes = PreregModes;

  constructor() { }

  ngOnInit(): void {
		this.modal = UIkit.modal('#collectModal', {
			bgClose: false,
			escClose: false,
		});
		UIkit.util.on('#collectModal', 'hidden', () => {
			this.candidateevent = null;
			this.init = null;
		});
  }

  showModal(candidateevent: CandidateEventModel): void {
    this.candidateevent = candidateevent;
    if (this.candidateevent && this.candidateevent.candidate) {
      this.init = {
        firstname: this.candidateevent.candidate.firstname,
        lastname: this.candidateevent.candidate.lastname,
        mobile: this.candidateevent.candidate.mobile,
      };
    }
    setTimeout(() => {
      this.modal.show();
    }, 200);
  }

  hideModal(): void {
    this.modal.hide();
  }

  onCollected(data: any): void {
    this.collected.emit(data);
  }

}
