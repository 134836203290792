import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'sb-ui-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() message: string = 'Loading...';
  @Input() full = false;

  constructor() { }

  ngOnInit() {
  }

}
