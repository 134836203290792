import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

	constructor(public transloco: TranslocoService) { }

	@Input() mode: string = null;
	@Input() messageLoading: string = null;
	@Input() messageSuccess: string = null;
	@Input() messageError: string = null;

	ngOnInit() {
	}

}
