import { parsePhoneNumber, CountryCode } from 'libphonenumber-js/min';

import { Deserializable } from './deserializable.model';
import { CandidateEventModel } from './candidateevent.model';

export class CandidateModel implements Deserializable {
	// columns
	id: string;
	email: string;
	password: string;
	email_verified_at: number;
	salutation: string;
	firstname: string;
	middlename: string;
	lastname: string;
	address_street: string;
	address_zip: string;
	address_city: string;
	address_state: string;
	address_country: string;
	phone: string;
	mobile: string;
	website_access_token: string;
	website_passthrough: string;
	status: string;
	profile_data: any;
	avatar_image: string;
	remember_token: string;
	was_noshow: number;
	search_radius: number;
	registration_source: string;
	privacypolicy_id: any;
	privacypolicy_at: any;
	candidate_source: string;
	candidate_source_referral: string;
	// misc
	avatar_background: string;
  marketing_source: string;
	profile_status: any;
	events_status: any;
	anonymized_at: any;
	properties: any;
  zerberus_status: string;
  ms_deeplink: string;
	// pseudo properties
	textmessages_unvisited: number;
	textmessages_last: any;
  can_edit_profile: boolean;
	// relations
	candidateevents: CandidateEventModel[] = null;

	deserialize(input: any): this {
		Object.assign(this, input);
		let candidateevents: CandidateEventModel[] = null;
		if (this.candidateevents !== null && this.candidateevents !== undefined) {
			candidateevents = [];
			this.candidateevents.forEach((candidateevent: CandidateEventModel) => {
				candidateevents.push(new CandidateEventModel().deserialize(candidateevent));
			});
		}
		this.candidateevents = candidateevents;
		return this;
	}

	// base data

	get fullname(): string {
		return this.firstname + ' ' + this.lastname;
	}
	set fullname(value: string) {
		const words = value.split(' ');
		this.firstname = words[0] || '';
		this.lastname = words[1] || '';
	}

	get mobile_human(): string {
		let phoneNumber = '';
		try {
			phoneNumber = parsePhoneNumber('+' + this.mobile, this.address_country as CountryCode).formatInternational();
		} catch (e) {
			phoneNumber = this.mobile;
		}
		return phoneNumber;
	}

	get mobile_plain(): string {
		return this.mobile;
	}

	get address_zipstate(): string {
		const parts: Array<string> = [];
		if (this.address_zip !== null) {
			parts.push(this.address_zip);
		}
		if (this.address_state !== null) {
			parts.push(this.address_state);
		}
		return parts.join(' ');
	}

	get real_avatar_image() {
		return (this.avatar_image !== null) ? this.avatar_image : 'assets/layout/images/avatar.jpg';
	}
	// debug

	get debug_eventlist(): string {
		const data: Array<any> = [];
		if (this.candidateevents !== null && this.candidateevents.length > 0) {
			this.candidateevents.forEach((candidateevent: CandidateEventModel) => {
				if (candidateevent.event !== null) {
					data.push(candidateevent.event.selectable_name + '<br><small>Status: ' + candidateevent.status + ', IDNumber: #' + candidateevent.idnumber + '</small>');
				}
			});
		}
		if (data.length > 0) {
			return data.join('<br>')
		} else {
			return 'N/A';
		}
	}

	get anonymized() {
		return this.anonymized_at !== null;
	}

	// candidate events

	hasProperty(type: string): boolean {
		return (Array.isArray(this.properties) && this.properties.indexOf(type) !== -1);
	}

	getNextCandidateEvent(): CandidateEventModel {
		if (this.candidateevents !== null && this.candidateevents.length > 0) {
			return this.candidateevents[0]; // @todo: get right event on date etc.
		} else {
			return null;
		}
	}

	// profile data

	getProfileStatus(): number {
		return this.getProfileInfo().value;
	}

	getNeededFieldList(): Array<string> {
		return this.getProfileInfo().list;
	}

	private getProfileInfo(): any {
		const fieldlist: Array<string> = [];
		const data = [
			'firstname',
			'lastname',
			'mobile',
			'email',
			'address_street',
			'address_zip',
			'address_city',
			'address_state'
		];
		const eeo = [
			'Disability',
			'Disabled Veteran',
			'Gender',
			'Military Status',
			'Race/Ethnicity'
		];
		const full = (this.hasProperty('SEARAY_ONLY')) ? data.length : data.length + eeo.length + 1; // 1 for career
		let filled = 0;
		data.forEach((value: string) => {
			if (
				(this as any)[value] !== '' &&
				(this as any)[value] !== null &&
				(this as any)[value] !== undefined
			) {
				filled++;
			} else {
				fieldlist.push('personal');
			}
		});
		eeo.forEach((value: string) => {
			if (
				this.profile_data !== undefined &&
				this.profile_data !== null &&
				this.profile_data.eeo !== null &&
				this.profile_data.eeo[value] !== '' &&
				this.profile_data.eeo[value] !== null &&
				this.profile_data.eeo[value] !== undefined
			) {
				filled++;
			} else {
				fieldlist.push('eeo');
			}
		});
		if (this.profile_data !== undefined && this.profile_data !== null && this.profile_data.career !== null) {
			filled++;
		} else {
			fieldlist.push('career');
		}
		if (this.hasTempEmail() === true) {
			filled--;
			filled = Math.max(filled, 0);
		}
		return {
			value: Math.ceil((filled / full) * 100),
			list: fieldlist.filter((value, index, self) => self.indexOf(value) === index)
		};
	}

	hasTempEmail() {
		if (this.registration_source !== undefined && this.registration_source !== null) {
			const parts = this.registration_source.split('.');
			if (parts.length >= 2 && parts[1] === 'no-email') {
				return true;
			}
		}
		return false;
	}

}
