<ng-container *transloco="let t;">
<div class="uk-section" *ngIf="mode === 'loading'">
	<p class="uk-text-center"><span uk-spinner="ratio: 3"></span></p>
	<p class="uk-text-center">
		{{messageLoading}}
	</p>
</div>
<div class="uk-section loader-success" *ngIf="mode === 'success'">
	<p class="uk-text-center"><span uk-icon="icon: check; ratio: 3"></span></p>
	<p class="uk-text-center">
		{{messageSuccess}}
	</p>
</div>
<div class="uk-section loader-error" *ngIf="mode === 'error'">
	<p class="uk-text-center"><span uk-icon="icon: warning; ratio: 3"></span></p>
	<p class="uk-text-center">
		{{messageError}}
	</p>
</div>
</ng-container>