import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

import * as moment from 'moment';

import { EventModel } from '../../../../sb-lib/src/lib/models/event.model';
import { JobtypeModel } from '../../../../sb-lib/src/lib/models/jobtype.model';
import { JoblocationModel } from '../../../../sb-lib/src/lib/models/joblocation.model';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root'
})
export class UiService {

	eventDetailStatus = new BehaviorSubject(null);
	messageList = new BehaviorSubject(null);

	constructor(
    protected confirmation: ConfirmationService,
    protected transloco: TranslocoService,
  ) { }

	showConfirm(message: string, successCallback: Function, errorCallback?: Function) {
    this.confirmation.confirm({
      header: this.transloco.translate('sblib.ui.confirm'),
      icon: 'pi pi-question-circle',
      message,
      accept: () => {
        successCallback();
      },
    });
	}

	showAlert(message: string, successCallback?: Function, errorCallback?: Function) {
    this.confirmation.confirm({
      header: this.transloco.translate('sblib.ui.alert'),
      icon: 'pi pi-exclamation-circle',
      message,
      acceptLabel: this.transloco.translate('common.ok'),
      rejectVisible: false,
      accept: () => {
        if (typeof successCallback === 'function') {
          successCallback();
        }
      },
    });
	}

	addMessage(content: string, type: string = 'info', group: string = null, timeout: number = 5000) {
		this.messageList.next({
			group: group,
			type: type,
			content: content,
			start: moment(),
			timeout: timeout
		});
	}

	resetMessage() {
		this.messageList.next(null);
	}

	getMessageList(): Observable<any> {
		return this.messageList.asObservable();
	}

	// event detail
	setEventDetail(event: EventModel, tab: string = null, jobtype: JobtypeModel = null, joblocation: JoblocationModel = null) {
		this.eventDetailStatus.next({
			event: event,
			tab: tab,
			jobtype: jobtype,
			joblocation: joblocation
		});
	}

	getEventDetailStatus(): Observable<any> {
		return this.eventDetailStatus.asObservable();
	}

}
