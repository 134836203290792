<div *ngIf="loading === true" class="uk-text-center uk-margin padding"><span uk-spinner></span><br>Loading...</div>

<div *ngIf="loading !== true">

    <div *ngIf="error !== true">
        <app-header></app-header>
        <app-navbar></app-navbar>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <app-ui></app-ui>
    </div>

    <div *ngIf="error === true">
        <p class="uk-text-center uk-text-danger padding"><span uk-icon="icon: warning; ratio: 2"></span><br>Website could not be loaded.</p>
    </div>

</div>
<p-confirmDialog [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}"></p-confirmDialog>
<p-toast key="toast" position="top-center"></p-toast>
