<div *ngIf="loading !== false" class="loading uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
	<div uk-spinner="ratio: 1.5"></div>
	<p>{{loadingMessage}}</p>
</div>
<div *ngIf="loading === false && error !== false" class="error uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
	<div class="container uk-flex uk-flex-center uk-flex-middle">
		<div uk-icon="icon: warning; ratio: 1.5"></div>
		<p>{{errorMessage}}</p>
	</div>
</div>

<ng-content *ngIf="loading === false && error === false"></ng-content>