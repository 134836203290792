<ng-container *transloco="let t;">
<form class="uk-form-stacked" novalidate (submit)="next()">
      <div class="uk-modal-body">
		<h2>{{ t('partials.preregistration.prereg_friday.work_history.professional_experience') }}</h2>
		<div *ngIf="form.get('nocareer').value !== true" class="uk-margin-top">
			<div uk-grid class="uk-child-width-1-3@m">
				<div *ngFor="let entry of form.get('career')['controls']; let i = index;">
				<p>{{ t('partials.preregistration.prereg_friday.work_history.job') }} {{i+1}} <a *ngIf="form.get('career')['controls'].length > 1" (click)="deleteCareerEntry(i)">{{ t('common.remove') }}</a>:</p>
				<div>
					<div>
						<sb-forms-input [control]="entry.get('employer')" [label]="t('partials.preregistration.prereg_friday.work_history.company')"></sb-forms-input>
					</div>
					<div>
						<sb-forms-input [control]="entry.get('occupation')" [label]="t('partials.preregistration.prereg_friday.work_history.jobtitle_position')"></sb-forms-input>
					</div>
					<div>
						<div uk-grid class="uk-grid-small uk-child-width-1-2">
							<div>
								<sb-forms-datepicker [control]="entry.get('from')" [label]="t('common.from')"  [min]="min" [max]="max"></sb-forms-datepicker>
							</div>
							<div>
								<sb-forms-datepicker [control]="entry.get('to')" [label]="t('common.to')"  [min]="min" [max]="max" [showPresentDay]="true"></sb-forms-datepicker>
							</div>
						</div>
					</div>
				</div>
				</div>
				<div *ngIf="form.get('career')['controls'].length &lt; 3">
					<p><a (click)="addCareerEntry()">{{ t('partials.preregistration.prereg_friday.work_history.add_professional_experience') }}</a></p>
				</div>
			</div>
			<hr>
		</div>
		<div uk-grid class="uk-child-width-1-3@m">
			<div><sb-forms-checkbox [control]="form.get('nocareer')" [label]="t('partials.preregistration.prereg_friday.work_history.no_professional_experience')" [hidevalue]="true" (changed)="onNoCareerChanged($event)"></sb-forms-checkbox></div>
		</div>

      </div>
      <div class="uk-modal-footer">
        <div uk-grid>
          <div class="uk-width-1-2@m">
            <p class="uk-text-small"><span uk-icon="icon: info"></span> {{ t('partials.preregistration.prereg_friday.work_history.fill_in_necessary_fields') }}</p>
          </div>
          <div class="uk-width-1-2@m uk-text-right">
            <button type="button" class="uk-button kn-button" (click)="back()">{{ t('common.back') }}</button>&nbsp;
            <button type="submit" class="uk-button kn-call-to-action">{{ t('common.next') }}</button>
          </div>
        </div>
      </div>
    </form>

</ng-container>
