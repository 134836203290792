<ng-container *transloco="let t;">
  <div class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
    <div class="uk-width-1-2">
      <h1>{{title}}</h1>

      <div class="uk-card uk-card-default uk-grid-collapse uk-margin" uk-grid>
        <div class="uk-width-2-3@s">
          <div class="uk-card-body" *ngIf="mode === UserLoginModes.Login">
            <p class="uk-card-title">{{ t('sblib.common.login') }}</p>
            <form class="uk-form-stacked" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
              <div class="uk-margin" [class.readonly]="events.length > 0">
                <sb-forms-input [control]="loginForm.get('email')" [label]="t('sblib.common.email')" [readonly]="events.length > 0"></sb-forms-input>
              </div>
              <div class="uk-margin" [class.readonly]="events.length > 0">
                <sb-forms-input [type]="'password'" [control]="loginForm.get('password')" [label]="t('sblib.common.password')" [readonly]="events.length > 0"></sb-forms-input>
              </div>
              <div class="uk-margin" *ngIf="events.length > 0">
                <p>{{ t('sblib.core.user_login.choose_event') }}</p>
                <sb-forms-select [control]="loginForm.get('event')" [label]="t('sblib.common.event')" [options]="events"></sb-forms-select>
              </div>
              <div *ngIf="error !== null">
                <p class="error">{{ t('sblib.core.user_login.' + error) }}</p>
              </div>
              <div>
                <button class="uk-button kn-call-to-action" type="submit" [disabled]="!loginForm.valid">{{ t('sblib.common.login') }}</button>
              </div>
            </form>
          </div>
          <div class="uk-card-body" *ngIf="mode === UserLoginModes.Password">
            <p class="uk-card-title">{{ t('sblib.core.user_login.change_password') }}</p>
            <p>{{ t('sblib.core.user_login.password_is_expired') }}</p>
            <sb-auth-change-password [email]="loginForm.get('email').value" (changed)="onPasswordChanged($event)" (cancelled)="onPasswordCancelled($event)"></sb-auth-change-password>
          </div>
        </div>
        <div class="uk-width-1-3@s uk-flex-first@s uk-card-media-left">
          <div class="uk-height-1-1 login-image"></div>
        </div>
      </div>

    </div>
  </div>
</ng-container>
