<ng-container *transloco="let t">
  <div uk-modal class="uk-modal-container" id="imagecropper">
    <div class="uk-modal-dialog">
      <div class="uk-modal-header">
        <div *ngIf="modeldata !== null">
          <h1>
            <sb-ui-avatar [url]="modeldata.file"></sb-ui-avatar> {{ t('sblib.ui.image_cropper.edit_image') }}
          </h1>
        </div>
      </div>

      <div class="uk-modal-body">
        <image-cropper
          [hidden]="imageChangedEvent === null"
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="1 / 1"
          [resizeToWidth]="250"
          format="png"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>

        <!-- <file-drop (onFileDrop)="dropped($event)"> -->
        <div class="uk-placeholder uk-text-center">
          <!-- <span uk-icon="icon: cloud-upload"></span>
					<span class="uk-text-middle">Attach binaries by dropping them here or</span> -->
          <div uk-form-custom>
            <input
              type="file"
              (change)="fileChangeEvent($event)"
              accept=".jpg,.jpeg,.heic,.gif,.png"
              #fileInput
            />
            <span class="uk-link"
              >{{ t('sblib.ui.image_cropper.click_to_select') }}
              <strong *ngIf="imageChangedEvent !== null">{{ t('sblib.ui.image_cropper.another') }}</strong>
              {{ t('sblib.ui.image_cropper.image') }}</span
            >
          </div>
        </div>
        <!-- </file-drop> -->

        <div
          class="progress form-group"
          style="background-color: #f0f0f0; height: 20px"
        >
          <div
            style="background-color: blue; height: 20px; transition: all 0.5s"
            class="progress-bar progress-bar-striped bg-success"
            role="progressbar"
            [style.width.%]="progress"
          ></div>
        </div>
      </div>

      <div class="uk-modal-footer uk-text-right">
        <sb-ui-button (click)="close()" [title]="t('sblib.common.close')"></sb-ui-button>&nbsp;
        <sb-forms-submitbutton
          [disabled]="
            modeldata === null ||
            modeldata.file === null ||
            modeldata.file === undefined
          "
          (click)="saveImage()"
          [submitting]="submitting"
          [title]="t('sblib.common.save')"
        ></sb-forms-submitbutton>
      </div>
    </div>
  </div>
</ng-container>
