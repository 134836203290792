<ng-container *transloco="let t;">
<div id="uiConfirm" uk-modal>
	<div class="uk-modal-dialog">
		<button class="uk-modal-close-default" type="button" uk-close></button>
		<div class="uk-modal-header">
			<h2 class="uk-modal-title">{{confirm.title}}</h2>
		</div>
		<div class="uk-modal-body">
			{{confirm.message}}
		</div>
		<div class="uk-modal-footer uk-text-right">
			<button class="uk-button kn-button uk-modal-close">{{confirm.no}}</button>&nbsp;<button class="uk-button kn-call-to-action" (click)="doConfirmYes()">{{confirm.yes}}</button>
		</div>
	</div>
</div>

<app-eventdetail></app-eventdetail>
<app-offcanvas></app-offcanvas>

</ng-container>