import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { LoginService } from '../../../services/login.service';
import { ApiService } from '../../../services/api.service';
import { UiService } from 'projects/microsite/src/app/services/ui.service';
import { VALIDATOR_PATTERN_EMAIL } from 'projects/microsite/src/app/services/data.service';

declare var UIkit: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	isLoggedIn: boolean = null;
	isPrivacyMode = false;

	passwordResetLoading = false;
	passwordResetSuccess = false;

	// form data
	loginForm = new UntypedFormGroup({
		accesscode: new UntypedFormControl('', Validators.required),
		lastname: new UntypedFormControl('', Validators.required),
		privacy: new UntypedFormControl(''),
	});
	passwordResetForm = new UntypedFormGroup({
		email: new UntypedFormControl('', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_EMAIL)]),
		lastname: new UntypedFormControl('', Validators.required),
	});

	constructor(
		private loginService: LoginService,
		private apiService: ApiService,
		private ui: UiService,
		private router: Router
	, public transloco: TranslocoService) { }

	ngOnInit() {
		this.loginService.isLoggedIn().subscribe(loggedIn => {
			this.isLoggedIn = loggedIn;
		});
	}

	showLogin() {
		UIkit.modal('#passwordResetModal', {}).hide();
		UIkit.modal('#loginModal', {}).show();
		UIkit.util.on('#loginModal', 'hidden', () => {
			this.loginForm.patchValue({
				accesscode: '',
				lastname: ''
			});
			this.loginForm.markAsPristine();
			this.loginForm.markAsUntouched();
			this.ui.resetMessage();
		});
	}


	showPasswordReset() {
		UIkit.modal('#loginModal', {}).hide();
		UIkit.modal('#passwordResetModal', {}).show();
		UIkit.util.on('#passwordResetModal', 'hidden', () => {
			this.passwordResetForm.patchValue({
				email: '',
				lastname: ''
			});
			this.passwordResetForm.markAsPristine();
			this.passwordResetForm.markAsUntouched();
			this.ui.resetMessage();
		});
	}


	showLogout() {
		UIkit.modal('#logoutModal', {}).show();
	}


	setPrivacyMode(mode: boolean) {
		this.isPrivacyMode = mode;
		if (this.isPrivacyMode === true) {
			this.loginForm.get('privacy').setValidators([Validators.required]);
		} else {
			this.loginForm.get('privacy').setValidators(null);
		}
	}


	doLogin() {
		this.ui.resetMessage();
		if (this.loginForm.valid === true) {
			const payload: any = {
				code: this.loginForm.value.accesscode,
				lastname: this.loginForm.value.lastname
			};
			if (this.isPrivacyMode === true) {
				payload.privacy = this.loginForm.value.privacy;
				this.setPrivacyMode(false);
			}
			this.apiService.login(payload).subscribe(
				(result) => {
					if (result.success === true) {
						this.loginForm.patchValue({
							accesscode: '',
							lastname: '',
							privacy: ''
						});
						UIkit.modal('#loginModal', {}).hide();
						this.router.navigate(['profile/my-profile']);
						if (result.noshow === true) {
							this.ui.showAlert(this.transloco.translate('partials.layout.login.we_missed_you_on_our_candidateday_if_you_have_any_questions_please_contact_us'));
						}
					} else {
						this.ui.addMessage(this.transloco.translate('partials.layout.login.your_last_name_or_confirmation_code_are_invalid_please_correct_your_input'), 'error', 'login');
					}
				},
				(error) => {
					if (error.error.status === 403) {
						this.setPrivacyMode(true);
					} else {
						this.ui.addMessage(this.transloco.translate('partials.layout.login.your_last_name_or_confirmation_code_are_invalid_please_correct_your_input'), 'error', 'login');
					}
				}
			);
		}
	}


	doResetPassword() {
		this.passwordResetLoading = true;
		this.passwordResetSuccess = false;
		this.ui.resetMessage();
		const payload = {
			email: this.passwordResetForm.value.email,
			lastname: this.passwordResetForm.value.lastname
		};
		this.apiService.resetPassword(payload).subscribe(
			(result) => {
				this.passwordResetLoading = false;
				this.passwordResetSuccess = true;
				this.passwordResetForm.patchValue({
					email: '',
					lastname: ''
				});
			},
			(error) => {
				this.passwordResetLoading = false;
				this.passwordResetSuccess = false;
				this.ui.addMessage(this.transloco.translate('partials.layout.login.your_last_name_or_e_mail_are_invalid_please_correct_your_input'), 'error', 'passwordreset');
			}
		);
	}


	doLogout() {
		this.loginService.setLoggedOut();
		UIkit.modal('#logoutModal', {}).hide();
		this.router.navigate(['/']);
	}

}
