import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class DataCoreService {

	constructor() { }

}
