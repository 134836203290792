import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var google: any;

@Injectable({
	providedIn: 'root'
})
export class GoogleMapsService {

	geocoder: any;

	constructor(
		private http: HttpClient,
	) {
		// this.mapsApiLoader = mapsApiLoader;
		// this.mapsApiLoader.load().then(() => {
		// 	this.geocoder = new google.maps.Geocoder();
		// });
	}

	getLocationData(value: string): Promise<any> {
		const result: any = {
			city: null,
			state: null,
			lat: null,
			lon: null,
		};
		if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
		return new Promise((resolve, reject) => {
			this.geocoder.geocode({
				address: value
			}, (results: Array<any>, status: string) => {
				if (status === google.maps.GeocoderStatus.OK) {
					let city = null;
					let state = null;
					results.forEach((entries: any) => {
						if (entries.geometry.location.lat) {
							result.lat = entries.geometry.location.lat();
						}
						if (entries.geometry.location.lng) {
							result.lon = entries.geometry.location.lng();
						}
						entries.address_components.forEach((component: any) => {
							const types = component.types;
							types.forEach((type: any) => {
								if (type === 'locality') {
									city = component.long_name;
								}
								if (type === 'administrative_area_level_1') {
									state = component.short_name;
								}
							});
						});
					});
					if (city !== null) {
						result.city = city;
					}
					if (state !== null) {
						result.state = state;
					}
					resolve(result);
				} else {
					reject(new Error('Couldnt\'t find the location ' + value));
				}
			});
		});
	}

	getTimeZone(lat: number, lon: number): Promise<any> {
		return new Promise((resolve, reject) => {
			reject();
			// timezone({
			// 	 location: lat + ',' + lon
			// }).then(result => {
			// 	console.log( result );
			// 	resolve(result);
			// }).catch( function( err ) {
			// 	console.log( err );
			// 	reject();
			// });
		});

		// const options = {
		// 	withCredentials: false
		// };
		// const queryOptions = {
		// 	location: lat + ',' + lon,
		// 	timestamp: new Date().getTime() / 1000,
		// 	key: GOOGLE_MAPS_API_KEY
		// };
		// const corsProxyUrl = CORS_PROXY_URL; // (isDevMode()) ? CORS_PROXY_URL : '';
		// const API_URL = TIMEZONE_URL;
		// const BASE_URL = corsProxyUrl + API_URL;
		// const queryString = this.encodeQuery(queryOptions);
		// const callUrl = BASE_URL + '/json?' + queryString;
		// return this.http.get(callUrl, options).pipe(
		// 	map((tsResult: any) => {
		// 		return tsResult.timeZoneId;
		// 	})
		// );
	}

	encodeAddress(address: any) {
		const parts = [];
		if (address.street) {
			parts.push(address.street.replace(' ', '+'));
		}
		if (address.city) {
			parts.push(address.city.replace(' ', '+'));
		}
		if (address.state) {
			let state = address.state.replace(' ', '+');
			if (address.zip) {
				state += '+' + address.zip.replace(' ', '+');
			}
			parts.push(state);
		} else if (address.zip) {
			parts.push(address.zip.replace(' ', '+'));
		}
		return parts.join(',+');
	}

	encodeQuery(data: any) {
		const ret = [];
		for (let d in data) {
			if (data.hasOwnProperty(d)) {
				ret.push(
					encodeURIComponent(d) + '=' + encodeURIComponent(data[d])
				);
			}
		}
		return ret.join('&');
	}
}
