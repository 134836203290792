import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';

declare var UIkit: any;

@Component({
	selector: 'app-ui',
	templateUrl: './ui.component.html',
	styleUrls: ['./ui.component.scss']
})
export class UiComponent implements OnInit {

	confirm: any = {
		title: null,
		message: null,
		no: null,
		yes: null
	};

	constructor(public transloco: TranslocoService) { }

	ngOnInit() {
	}

	showConfirm(title: string, message: string, yes?: string, no?: string) {
		this.confirm = {
			title: title,
			message: message,
			yes: (yes !== null) ? yes : this.transloco.translate('partials.layout.ui.oui'),
			no: (no !== null) ? no : this.transloco.translate('partials.layout.ui.non')
		};
		UIkit.modal('#uiConfirm', {}).show();
	}

	doConfirmYes() {

	}

}
