import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	constructor(public transloco: TranslocoService) { }

	ngOnInit() {
	}

}
