import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Md5 } from 'ts-md5';

import { CURRENCIES_SHORT } from '../../../models/constants';
import { Observable, interval } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
	providedIn: 'root'
})
export class AppCoreService {

	setupData: any = null;
	enums: any = null;
	timer: Observable<number> = null;

	constructor(
		@Inject(DOCUMENT) protected document: HTMLDocument,
    protected transloco: TranslocoService,
		public deviceService: DeviceDetectorService
	) {
		this.timer = interval(1000);
	}

	// rights

	hasRight(right: string): boolean {
		switch (right) {
			case 'editLocations': return this.getApptype() !== 'SB_US';
			case 'editHiringmanagers': return this.getApptype() !== 'SB_US';
			case 'showI9Location': return this.getApptype() === 'SB_US';
			case 'showClickboarding': return this.getApptype() === 'SB_US';
			case 'showUsScreeningpackage': return this.getApptype() === 'SB_US';
		}
		return false;
	}

	// setup data

	setSetupData(setupData: any) {
		this.setupData = setupData;
		this.setFavIcon(this.setupData.apptype);
    this.setVisitorHash(this.setupData.visitor_hash);
		Object.keys(this.setupData.theme).forEach(property => {
			document.documentElement.style.setProperty(
			  property,
			  this.setupData.theme[property]
			);
		});
	}

  setEnums(data: any) {
    this.enums = data;
  }

  getEnum(type: string, translated = true): any {
    if (this.enums && this.enums.hasOwnProperty(type)) {
      if (translated === true) {
        const enums: any = {};
        Object.keys(this.enums[type]).forEach(key => {
          const prefix: string = 'sblib.enums.' + type + '.';
          enums[key] = this.transloco.translate(prefix.toLowerCase() + this.enums[type][key]);
        });
        return enums;
      }
      return this.enums[type];
    }
  }

	getRunningEvents(): any[] {
		return (this.setupData !== null) ? this.setupData.events : [];
	}

	getApptype(): string {
		return this.getValue('apptype');
	}

	getAppname(): string {
		return this.getValue('appname');
	}

	getWebsiteTitle(): string {
		return this.getValue('website_title');
	}

	getCountry(): string {
		return this.getValue('country');
	}

	getCurrency(): string {
		return this.getValue('currency');
	}

	getCurrencyShort(): string {
		const currency = this.getCurrency();
		return (CURRENCIES_SHORT[currency] !== undefined) ? CURRENCIES_SHORT[currency] : currency;
	}

	getAreacode(): string {
		return this.getValue('areacode');
	}

	protected getValue(key: any): any {
		return (this.setupData !== null) ? this.setupData[key] : '';
	}

	// misc

	getTimer(): Observable<number> {
		return this.timer;
	}

	isDesktop(): boolean {
		return this.deviceService.isDesktop();
	}

	setFavIcon(type: string) {
		// this.document.getElementById('appicon').setAttribute('href', 'favicon_' + type.toLowerCase() + '.ico');
	}

  setVisitorHash(hash: string) {
    const visitorHash = this.getVisitorHash();
    if (!visitorHash) {
      localStorage.setItem('visitor_hash', hash);
    }
  }

  getVisitorHash(): string {
    return localStorage.getItem('visitor_hash');
  }

  getHash(): string {
    const hash = new Md5();
    hash.appendStr((new Date()).getMilliseconds().toString());
    hash.appendStr(Math.floor(Math.random() * 1000).toString());
    return hash.end().toString();
  }

  // downloads

  downloadFile(content: any, filename: string): void {
    const regex = new RegExp('\.([^\.]+)$');
    let type = '*/*';
    const matches = filename.match(regex);
    if (matches !== null) {
      switch(matches[1]) {
        case 'zip': {
          type = 'application/zip';
          break;
        }
        case 'pdf': {
          type = 'application/pdf';
          break;
        }
      }
    }
		const blob = new Blob([content], { type });
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = filename;
		anchor.href = url;
		anchor.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
		setTimeout(() => {
			window.URL.revokeObjectURL(url);
			anchor.remove();
		}, 100);
  }

}
