import { Component, Input, OnInit } from '@angular/core';
import { CommonComponent } from '../common/common.component';

import * as _moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppCoreService } from '../../../core/services/app-core.service';
import { FormhelperService } from '../../services/formhelper.service';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'sb-forms-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent extends CommonComponent implements OnInit {
  public _min: Date = moment().toDate();
  public _max: Date = moment().add(10, 'years').toDate();
  public yearRange: string;

  @Input() mode: string = null;
  @Input() format: string = DATETIMES.DATE_PRIMEPICKER_LONG;
  @Input() showPresentDay = false;
  @Input() time: boolean = false;
  @Input() set min(min: any) {
    const value = moment(min);
    if (value.isValid()) {
      this._min = value.toDate();
    }
  }
  @Input() set max(max: any) {
    const value = moment(max);
    if (value.isValid()) {
      this._max = value.toDate();
    }
  }

  public dateTimes = DATETIMES;

  constructor(
    protected deviceService: DeviceDetectorService,
		protected app: AppCoreService,
		public fh: FormhelperService,
  ) {
    super(deviceService, app, fh);
  }

  ngOnInit(): void {
    let yearStart = moment().year();
    let yearEnd = moment().year();
    if (moment(this._min).isValid()) {
      yearStart = moment(this._min).year();
    }
    if (moment(this._max).isValid()) {
      yearEnd = moment(this._max).year()
    };
    this.yearRange = yearStart + ':' + yearEnd;
  }

  setPresentDay(): void {
    this.control.setValue(moment().startOf('day').toDate());
    this.control.markAsTouched();
  }

  getViewValue(): string {
    if (this.blurred === true) {
      return '**********';
    }
    const value = moment(this.control?.value);
    return value.isValid()
      ? value.format(DATETIMES.DATE_LONG)
      : '';
  }
}
