import { Component, Input } from '@angular/core';
import { CommonComponent } from '../common/common.component';

@Component({
	selector: 'sb-forms-textarea',
	templateUrl: './textarea.component.html',
	styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent extends CommonComponent {
	@Input() microsite = false;
}
