import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sb-forms-submitbutton',
	templateUrl: './submitbutton.component.html',
	styleUrls: ['./submitbutton.component.scss']
})
export class SubmitbuttonComponent implements OnInit {
	@Input() title: string = null;
	@Input() small = false;
	@Input() submitting = false;
	@Input() disabled = false;
	@Input() type = 'submit';
	@Output() clicked = new EventEmitter<void>();

	constructor() { }

	ngOnInit() {
	}

	onClick() {
		if (this.type !== 'submit') {
			this.clicked.emit();
		}
	}

}
