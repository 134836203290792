import { TranslocoService } from "@ngneat/transloco";

export class BaseModel {
  updateable: boolean;
  deletable: boolean;
  transloco: any;

  deserialize(input: any): this {
    Object.assign(this, input);
    return this;
  }

  setTransloco(transloco: any): void {
    this.transloco = transloco;
  }

  propertyFilled(property: any): boolean {
    return property !== null && property !== undefined && property !== '';
  }
}
