import { Deserializable } from './deserializable.model';
import { EventModel } from './event.model';
import { JobtypeModel } from './jobtype.model';
import { JoblocationModel } from './joblocation.model';
import { HiringmanagerModel } from './hiringmanager.model';
import { OfferletterModel } from './offerletter.model';

export class JobModel implements Deserializable {
	// columns
	id: string;
	event_id: string;
	jobtype_id: string;
	joblocation_id: string;
	i9location_id: string;
	hiringmanager_id: string;
  offerletter_id: string;
	date_start: string;
	amount: number;
	data: any;
	assigned: number;
	event: EventModel;
	jobtype: JobtypeModel;
	joblocation: JoblocationModel;
	hiringmanager: HiringmanagerModel;
	offerletter: OfferletterModel;
	properties: any;
	supervisor: string;

	select_value: string; // @todo temp for recruiter_dashboard

	deserialize(input: any): this {
		Object.assign(this, input);
		if (this.event !== null && this.event !== undefined) {
			this.event = new EventModel().deserialize(this.event);
		}
		if (this.jobtype !== null && this.jobtype !== undefined) {
			this.jobtype = new JobtypeModel().deserialize(this.jobtype);
		}
		if (this.joblocation !== null && this.joblocation !== undefined) {
			this.joblocation = new JoblocationModel().deserialize(this.joblocation);
		}
		if (this.hiringmanager !== null && this.hiringmanager !== undefined) {
			this.hiringmanager = new HiringmanagerModel().deserialize(this.hiringmanager);
		}
		if (this.offerletter !== null && this.offerletter !== undefined) {
			this.offerletter = new OfferletterModel().deserialize(this.offerletter);
		}
		return this;
	}

}



