export const GOOGLE_MAPS_OPTIONS: google.maps.MapOptions = {
  mapTypeId: google.maps.MapTypeId.ROADMAP,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
  },
  scrollwheel: false,
  streetViewControl: false,
  disableDoubleClickZoom: true,
  zoom: 12,
  maxZoom: 15,
  minZoom: 8,
};
