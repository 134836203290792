import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'sb-forms-container',
	templateUrl: './container.component.html',
	styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

	@Input() type: string = null;
	@Input() label: string = null;
	@Input() errors: any = {};
	@Input() microsite = false;

	constructor() { }

	ngOnInit() {
	}

}
