import { Component, AfterViewInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
	selector: 'sb-ui-signaturefield',
	templateUrl: './signaturefield.component.html',
	styleUrls: ['./signaturefield.component.scss']
})
export class SignaturefieldComponent implements AfterViewInit {

	@Output() signed = new EventEmitter<string | null>();

  @ViewChild('signature')
  public signaturePad: SignaturePadComponent;

  public isSigned = false;
  public signaturePadOptions: NgSignaturePadOptions = {
		minWidth: 2,
		maxWidth: 4,
		canvasWidth: 688,
		canvasHeight: 250,
    backgroundColor: '#ffffff'
  };

  constructor() {}

  ngAfterViewInit() {
    this.signaturePad.clear();
  }

  public drawComplete(event: MouseEvent | Touch) {
    this.isSigned = true;
  }

  public drawStart(event: MouseEvent | Touch) {
  }

	public clear() {
		this.signaturePad.clear();
		this.isSigned = false;
	}

	public setSignature() {
		this.signed.emit((this.isSigned === true) ? this.signaturePad.toDataURL() : null);
	}

}
