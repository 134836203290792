<div class="form-field" [ngClass]="{'error': isInvalid(), 'success': isValid(), 'busy': isBusy(), 'readonly': readonly, 'is-desktop': isDesktop(), 'short': short === true, 'microsite': microsite === true}">
	<sb-forms-container [label]="label" [errors]="control.errors" [type]="type" [microsite]="microsite">
		<div class="input-field uk-inline uk-width-1-1" (click)="setEditable()">
			<input *ngIf="(readonly === true || blurred === true) && control.value !== null && control.value !== undefined && control.value !== ''" class="uk-input" [type]="(blurred === true) ? 'password' : type" [value]="getViewValue()" [readonly]="readonly">
			<input *ngIf="(readonly === true || blurred === true) && (control.value === null || control.value === undefined || control.value === '')" class="uk-input" [type]="(blurred === true) ? 'password' : type" value="" [readonly]="readonly">
			<select [hidden]="readonly === true || blurred === true" class="uk-select" [formControl]="control" #input (change)="onChanged()">
				<option [ngValue]="null" *ngIf="isRequired() !== true || (options !== undefined && options.length > 1)"></option>
				<option *ngFor="let option of options" [ngValue]="option" [disabled]="option.disabled === true">{{option['select_value']}}</option>
			</select>
		</div>
	</sb-forms-container>
</div>
