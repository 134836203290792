<div>
	<round-progress
		class="clock"
		[current]="current"
		[max]="max"
		[radius]="radius"
		[color]="color"
		[background]="background"
		[stroke]="stroke"
	></round-progress>
	<div class="text" [ngClass]="{'small': radius &lt; 25}">{{ text }}</div>
</div>
