import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'sb-ui-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	@Input() url: string = null;
	@Input() mini = false;
	@Input() type = 'candidate';

	constructor() { }

	ngOnInit() {
	}

}
