import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {

	public apptype: string = null;
  public url: string;
  public content: any;
  public loading = true;

	constructor(
		private app: AppService,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
	) { }

	ngOnInit() {
    this.apptype = this.app.getApptype();
    this.route.url.subscribe((url: Array<UrlSegment>) => {
      if (url[0]) {
        this.url = url[0].path;
        this.api.getContent(this.url).subscribe((result: any) => {
          this.content = this.sanitizer.bypassSecurityTrustHtml(result.content);
        }, () => {
          this.redirectHome();
        }).add(() => {
          this.loading = false;
        });
      } else {
        this.redirectHome();
      }
    });
	}

  private redirectHome(): void {
    this.router.navigate(['/']);
  }

}
