<ng-container *transloco="let t;">
<form class="uk-form-stacked" novalidate (submit)="register()">
    <div class="uk-modal-body">
		<app-slotselector [event]="event" [mode]="'fallback-select'" (set)="setSlot($event)"></app-slotselector>
    </div>
    <div class="uk-modal-footer">
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <p class="uk-text-small"><span uk-icon="icon: info"></span> {{ t('partials.preregistration.prereg_friday.slot.fill_in_necessary_fields') }}</p>
        </div>
        <div class="uk-width-1-2@m uk-text-right">
          <button type="button" class="uk-button kn-button" (click)="back()">{{ t('common.back') }}</button>&nbsp;
          <button type="submit" class="uk-button kn-call-to-action">{{ t('partials.preregistration.prereg_friday.slot.register') }}</button>
        </div>
      </div>
    </div>
</form>

</ng-container>
