import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { REASONS } from 'projects/sb-lib/src/lib/models/constants';

@Component({
  selector: 'sb-ui-offerletter-decline',
  templateUrl: './offerletter-decline.component.html',
  styleUrls: ['./offerletter-decline.component.scss']
})
export class OfferletterDeclineComponent implements OnInit {

	reasons: any = null;
	activeReasons: Array<string> = [];

	@Input() busy: boolean;
	@Output() closed = new EventEmitter<null>();
	@Output() declined = new EventEmitter<Array<string>>();

	constructor() { }

	ngOnInit() {
		this.reasons = REASONS;
	}

	close(): void {
		this.closed.emit();
	}

	decline(): void {
		if (this.activeReasons.length > 0 && !this.busy) {
			this.declined.emit(this.activeReasons);
		}
	}

	setReason(reason: string) {
		if (this.activeReasons.indexOf(reason) !== -1) {
			if (this.activeReasons.length > 1) {
				this.activeReasons = this.activeReasons.filter((element: string) => {
					return element !== reason;
				});
			}
		} else {
			this.activeReasons.push(reason);
		}
	}

}
