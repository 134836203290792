import { Deserializable } from './deserializable.model';

import { EventModel } from './event.model';
import { EventdayModel } from './eventday.model';
import { UserModel } from './user.model';
import { SlotModel } from './slot.model';

export class SlotlineModel implements Deserializable {
	// columns
	id: string;
	event_id: string;
	eventday_id: string;
	recruiter_id: string;
	// relations parent
	event: EventModel;
	eventday: EventdayModel;
	recruiter: UserModel;
	// relations childs
	slots: SlotModel[];

	deserialize(input: any): this {
		Object.assign(this, input);
		if (this.event !== null && this.event !== undefined) {
			this.event = new EventModel().deserialize(this.event);
		}
		if (this.eventday !== null && this.eventday !== undefined) {
			this.eventday = new EventdayModel().deserialize(this.eventday);
		}
		if (this.recruiter !== null && this.recruiter !== undefined) {
			this.recruiter = new UserModel().deserialize(this.recruiter);
		}
		// slots
		let slots: SlotModel[] = null;
		if (this.slots !== null && this.slots !== undefined) {
			slots = [];
			this.slots.forEach((slot: SlotModel) => {
				slots.push(new SlotModel().deserialize(slot));
			});
		}
		this.slots = slots;
		return this;
	}

}



