<ng-container *transloco="let t;">
  <form class="uk-form-stacked" novalidate (submit)="register()">
    <div class="uk-modal-body">
		<h2>{{ t('partials.preregistration.prereg_friday.job_skills.your_position') }}</h2>
		<app-jobselector [event]="event" [showValidation]="false" [mode]="'register-friday'" (changed)="jobChanged($event)"></app-jobselector>
		<div *ngIf="jobtypeId !== null">
			<hr>
			<h2>{{ t('partials.preregistration.prereg_friday.job_skills.your_skills') }}</h2>
			<app-skillselector [event]="event" [showValidation]="false" [mode]="'register-friday'" [jobtypeId]="jobtypeId" (changed)="skillsChanged($event)"></app-skillselector>
		</div>
    </div>
    <div class="uk-modal-footer">
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <p class="uk-text-small"><span uk-icon="icon: info"></span> {{ t('partials.preregistration.prereg_friday.job_skills.fill_in_necessary_fields') }}</p>
        </div>
        <div class="uk-width-1-2@m uk-text-right">
          <button type="button" class="uk-button kn-button" (click)="back()">{{ t('common.back') }}</button>&nbsp;
		      <button type="submit" class="uk-button kn-call-to-action" [disabled]="skillsValid !== true">{{ t('partials.preregistration.prereg_friday.job_skills.register') }}</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
