<ng-container *transloco="let t">
  <div
    class="form-field"
    [ngClass]="{
      error: isInvalid(),
      success: isValid(),
      busy: isBusy(),
      readonly: readonly,
      'is-desktop': isDesktop(),
      short: short === true
    }"
  >
    <sb-forms-container
      [label]="label"
      [errors]="control.errors"
      [type]="'datepicker'"
    >
      <div class="input-field uk-inline uk-width-1-1" (click)="setEditable()">
        <input
          *ngIf="readonly === true || blurred === true"
          class="uk-input"
          [type]="blurred === true ? 'password' : type"
          [value]="getViewValue()"
          [readonly]="readonly"
        />
        <div [hidden]="readonly === true || blurred === true">
          <p-calendar
            [formControl]="control"
            [showTime]="time"
            [dateFormat]="format"
            [hourFormat]="dateTimes.HOUR_FORMAT"
            [minDate]="_min"
            [maxDate]="_max"
            [firstDayOfWeek]="firstDayOfWeek"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="yearRange"
            [touchUI]="!isDesktop()"
            [showButtonBar]="true"
            appendTo="body"
          ></p-calendar>
        </div>
      </div>
      <div class="input-functions">
        <a
          *ngIf="readonly !== true && showPresentDay === true"
          (click)="setPresentDay()"
          >{{ t("sblib.forms.present_day") }}</a
        >
        <a
          (click)="resetValue()"
          *ngIf="
            readonly !== true && control.value !== '' && control.value !== null
          "
          ><span uk-icon="icon: close; ratio: 0.8"></span>
          {{ t("sblib.common.clear") }}</a
        >
      </div>
    </sb-forms-container>
  </div>
</ng-container>
