import { Component, OnInit, Input } from '@angular/core';

import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'sb-forms-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent extends CommonComponent implements OnInit {

	@Input() hidevalue: boolean = false;
	@Input() cbfirst: boolean = false;

	ngOnInit() {
	}

}
