import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { TranslocoService } from '@ngneat/transloco';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  messageList = new BehaviorSubject(null);

  imageCropper = new BehaviorSubject({
    open: false,
    model: null,
    modeltype: null,
    imagetype: null,
    file: null,
    imagetarget: null,
  });

  constructor(
    protected transloco: TranslocoService,
    protected messageService: MessageService,
    protected confirmation: ConfirmationService
  ) {}

  showConfirm(message: string, successCallback: Function) {
    this.confirmation.confirm({
      header: this.transloco.translate('sblib.ui.confirm'),
      icon: 'pi pi-question-circle',
      message,
      accept: () => {
        successCallback();
      },
    });
  }

  showAlert(message: string, okCallback?: Function) {
    this.confirmation.confirm({
      header: this.transloco.translate('sblib.ui.alert'),
      icon: 'pi pi-exclamation-circle',
      message,
      acceptLabel: this.transloco.translate('common.ok'),
      rejectVisible: false,
      accept: () => {
        if (typeof okCallback === 'function') {
          okCallback();
        }
      },
    });
  }

  addMessage(
    content: string,
    type: string = 'info',
    group: string = null,
    timeout: number = 5000
  ) {
    this.messageList.next({
      group: group,
      type: type,
      content: content,
      start: moment(),
      timeout: timeout,
    });
  }

  resetMessage() {
    this.messageList.next(null);
  }

  getMessageList(): Observable<any> {
    return this.messageList.asObservable();
  }

  // image cropper

  openImageCropper(
    model: any,
    modeltype: string,
    imagetype: string,
    file: any,
    imagetarget: any
  ) {
    this.imageCropper.next({
      open: true,
      model: model,
      modeltype: modeltype,
      imagetype: imagetype,
      file: file,
      imagetarget: imagetarget,
    });
  }

  closeImageCropper() {
    this.imageCropper.next({
      open: false,
      model: null,
      modeltype: null,
      imagetype: null,
      file: null,
      imagetarget: null,
    });
  }

  imageCropperStatus(): Observable<any> {
    return this.imageCropper.asObservable();
  }

  // PRIME

  public toastSuccess(message: string, topline: any = null): void {
    this.messageService.add({
      key: 'toast',
      severity: 'success',
      summary: topline || this.transloco.translate('sblib.common.success'),
      detail: message,
    });
  }

  public toastError(message: string, topline: any = null): void {
    this.messageService.add({
      key: 'toast',
      severity: 'error',
      summary: topline || this.transloco.translate('sblib.common.error'),
      detail: message,
      sticky: true,
    });
  }

  public toastInfo(message: string, topline: any = null, life = 3000): void {
    this.messageService.add({
      key: 'toast',
      severity: 'info',
      summary: topline || this.transloco.translate('sblib.common.info'),
      detail: message,
      life,
    });
  }

  public showMessage(type: string, message: string, topline = ''): void {
    this.messageService.add({
      severity: type,
      summary: topline,
      detail: message,
    });
  }
}
