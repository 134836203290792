import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

const COLOR_BASE = '#56B7E9';
const COLOR_RED = '#d30000';
const COLOR_YELLOW = '#ffd400';

@Component({
	selector: 'sb-ui-circular',
	templateUrl: './circular.component.html',
	styleUrls: ['./circular.component.scss']
})
export class CircularComponent implements OnChanges {
	@Input() current: number;
	@Input() radius: number;
	@Input() max = 100;
	@Input() background = '#eee';
	@Input() color = COLOR_BASE;
	@Input() stroke = 7;
	@Input() text = '';

	ngOnChanges(changes: SimpleChanges): void {
		if (this.current >= 99) {
			this.color = COLOR_BASE;
		} else if (this.current >= 75) {
			this.color = COLOR_YELLOW;
		} else  {
			this.color = COLOR_RED;
		}
	}

}
