import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-listselector',
	templateUrl: './listselector.component.html',
	styleUrls: ['./listselector.component.scss']
})
export class ListselectorComponent implements OnInit {

	constructor(public transloco: TranslocoService) { }

	@Input() values: string[] = null;
	@Input() active: string[] = null;
	@Output() clicked = new EventEmitter<string>();

	ngOnInit() {
	}

	setActive(value: string) {
		this.clicked.emit(value);
	}

}
