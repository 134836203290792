<ng-container *transloco="let t;">
  <form class="uk-form-stacked" [formGroup]="passwordForm" (ngSubmit)="onSubmit()" novalidate>
    <div class="uk-margin">
      <sb-forms-input [type]="'password'" [control]="passwordForm.get('old')" [label]="t('sblib.core.change_password.old_password')"></sb-forms-input>
    </div>
    <div class="uk-margin">
      <sb-forms-input [type]="'password'" [control]="passwordForm.get('new')" [label]="t('sblib.core.change_password.new_password')"></sb-forms-input>
    </div>
    <div class="uk-margin">
      <sb-forms-input [type]="'password'" [control]="passwordForm.get('confirm')" [label]="t('sblib.core.change_password.password_confirmation')"></sb-forms-input>
    </div>
    <div class="p-text-right">
      <button pButton type="button" [disabled]="submitting" (click)="onCancel($event)" [label]="t('sblib.common.cancel')" class="p-button-outlined"></button>
      <button pButton type="submit" [disabled]="!passwordForm.valid" [loading]="submitting" [label]="t('sblib.common.change')" class="p-ml-2"></button>
    </div>
  </form>
</ng-container>
