import { Component } from '@angular/core';
import { CommonComponent } from '../common/common.component';

@Component({
	selector: 'sb-forms-input',
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss']
})
export class InputComponent extends CommonComponent {
}
