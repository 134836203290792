import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

import { ApiService } from '../../../api/services/api.service';
import { UiService } from '../../services/ui.service';
import { HttpEventType, HttpEvent } from '@angular/common/http';

declare var UIkit: any;

@Component({
	selector: 'sb-ui-image-cropper',
	templateUrl: './image-cropper.component.html',
	styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

	@ViewChild('fileInput') fileInput: any;

	dialog: any = null;
	modeldata: any = null;
	file: any = null;
	files: FileList = null;
	submitting = false;
	positions: any = null;

	progress: number = 0;

	imageChangedEvent: any = null;

	constructor(
		private ui: UiService,
		private api: ApiService
	) { }

	ngOnInit() {
		this.dialog = UIkit.modal('#imagecropper', {
			escClose: false,
			bgClose: false,
		});
		if (this.dialog !== undefined) {
			this.ui.imageCropperStatus().subscribe((data: any) => {
				if (data.open === true) {
					this.file = data.file;
					this.modeldata = data;
					this.dialog.show();
				} else {
					this.dialog.hide();
					this.file = null;
					this.modeldata = null;
					this.imageChangedEvent = null;
				}
			});
		}
	}

	close() {
		this.ui.closeImageCropper();
	}


	fileChangeEvent(event: any): void {
		this.files = event.target.files;
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.modeldata.file = event.base64;
		this.positions = event.imagePosition;
	}

	imageLoaded() {
	}

	cropperReady() {
	}

	loadImageFailed() {
	}

	saveImage() {
		const payload: FormData = new FormData();
		payload.append('file', this.files[0], this.files[0].name);
		payload.append('positions', JSON.stringify(this.positions));
		this.submitting = true;
		this.api.postResource(this.modeldata.modeltype, this.modeldata.model.id, this.modeldata.imagetype, payload).subscribe((event: HttpEvent<any>) => {

			switch (event.type) {
				case HttpEventType.Sent:
					console.log('Request has been made!');
					break;
				case HttpEventType.ResponseHeader:
					console.log('Response header has been received!');
					break;
				case HttpEventType.UploadProgress:
					this.progress = Math.round(event.loaded / event.total * 100);
					console.log(`Uploaded! ${this.progress}%`);
					break;
				case HttpEventType.Response:
					this.modeldata.model.avatar_image = event.body;
					this.modeldata.imagetarget.src = event.body;
					console.log(event.body);
					this.submitting = false;
					this.modeldata = null;
					this.close();
					setTimeout(() => {
						this.progress = 0;
					}, 200);

			  }

		}, () => {
			this.submitting = false;
		});
	}

}
