import { Component, Input } from '@angular/core';
import { CommonComponent } from '../common/common.component';
import { DATETIMES } from 'projects/sb-lib/src/lib/models/constants';

import * as _moment from 'moment';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
	selector: 'sb-forms-timepicker',
	templateUrl: './timepicker.component.html',
	styleUrls: ['./timepicker.component.scss']
})
export class TimepickerComponent extends CommonComponent {

  public _min: Date | null = null;
  public _max: Date | null = null;
  public dateTimes = DATETIMES;

	@Input() mode: string = null;
	@Input() startAt: any = null;
	@Input() stepMinute = 5;
  @Input() set min(min: any) {
    const value = moment(min);
    if (value.isValid()) {
      this._min = value.toDate();
    }
  }
  @Input() set max(max: any) {
    const value = moment(max);
    if (value.isValid()) {
      this._max = value.toDate();
    }
  }

	getViewValue(): string {
		const value = moment(this.control?.value);
		return (value.isValid()) ? value.format(this.getHourFormat()) : null;
	}

  private getHourFormat(): string {
    return (DATETIMES.HOUR_FORMAT === 12) ? 'hh:mm A' : 'HH:mm';
  }

}
