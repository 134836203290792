import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MomentModule } from 'ngx-moment';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { CalendarModule } from 'primeng/calendar';

import { CommonComponent } from './components/common/common.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { InputComponent } from './components/input/input.component';
import { SelectComponent } from './components/select/select.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ZipcityComponent } from './components/zipcity/zipcity.component';
import { PhoneComponent } from './components/phone/phone.component';
import { SubmitbuttonComponent } from './components/submitbutton/submitbutton.component';
import { ContainerComponent } from './components/container/container.component';

@NgModule({
	declarations: [
		CommonComponent,
		CheckboxComponent,
		DatepickerComponent,
		TimepickerComponent,
		InputComponent,
		SelectComponent,
		MultiselectComponent,
		TextareaComponent,
		ZipcityComponent,
		PhoneComponent,
		SubmitbuttonComponent,
		ContainerComponent
	],
	imports: [
		CommonModule,
		ReactiveFormsModule,
		MomentModule,
		NgxPageScrollCoreModule,
    CalendarModule,
    TranslocoModule
	],
	exports: [
		CommonComponent,
		CheckboxComponent,
		DatepickerComponent,
		TimepickerComponent,
		InputComponent,
		SelectComponent,
		MultiselectComponent,
		TextareaComponent,
		ZipcityComponent,
		PhoneComponent,
		SubmitbuttonComponent
	],
})
export class SbFormsModule {}
