import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sb-ui-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

	@Input() title: string = null;
	@Input() icon: string = null;
	@Input() working = false;
	@Input() disabled = false;
	@Input() small = false;
	@Output() clicked = new EventEmitter<void>();

	constructor() { }

	ngOnInit() {
	}

	onClicked() {
		this.clicked.emit();
	}

}
