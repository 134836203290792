import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'sb-ui-datalist',
	templateUrl: './datalist.component.html',
	styleUrls: ['./datalist.component.scss']
})
export class DatalistComponent implements OnInit {
	@Input() data: Array<any> = null;
	@Input() mapping: any;
	@Output() editClicked = new EventEmitter<any>();
	@Output() deleteClicked = new EventEmitter<any>();

	constructor() { }

	ngOnInit() {
	}

	onEditClicked(entry: any) {
		this.editClicked.emit(entry);
	}

	onDeleteClicked(entry: any) {
		this.deleteClicked.emit(entry);
	}

}
