import { TranslocoService } from '@ngneat/transloco';
import { OfferletterComponent as OlComponent } from 'projects/sb-lib/src/lib/modules/ui/components/offerletter/offerletter.component';
import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-offerletter',
  templateUrl: './offerletter.component.html',
  styleUrls: ['./offerletter.component.scss']
})
export class OfferletterComponent implements OnInit {

	signing_id: string = null;
	candidateevent: CandidateEventModel = null;

	isAccepted = false;
	isDeclined = false;
	isSigned = false;

	@ViewChild('offerletter') offerletter: OlComponent;

	constructor(
		private api: ApiService,
		private route: ActivatedRoute,
		private router: Router
	, public transloco: TranslocoService) { }

	ngOnInit() {
		this.signing_id = this.route.snapshot.paramMap.get('id');
		if (this.signing_id !== null) {
			this.api.getOfferletter(this.signing_id).subscribe((candidateevent: any) => {
				this.candidateevent = candidateevent;
			}, () => {
				this.router.navigate(['/']);
			});
		} else {
			this.router.navigate(['/']);
		}
	}

	accept(signatureUrl: string): void {
		this.offerletter.setAccepting();
		this.api.acceptOfferletter(this.signing_id, signatureUrl).subscribe(() => {
			this.offerletter.setAccepting(false);
			this.isAccepted = true;
		});
	}

	decline(reasons: Array<string>): void {
		this.offerletter.setDeclining();
		this.api.declineOfferletter(this.signing_id, reasons).subscribe(() => {
			this.offerletter.setDeclining(false);
			this.isDeclined = true;
		});
	}

	print(): void {
		window.print();
	}

}
