import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';

import { CandidateModel } from '../../../../sb-lib/src/lib/models/candidate.model';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	loggedIn = new BehaviorSubject(false);

	constructor() {
		this.loggedIn.next(sessionStorage.getItem('loggedIn') === '1');
	}

	setLoggedIn(authToken: string, candidate: CandidateModel) {
		this.loggedIn.next(true);
		sessionStorage.setItem('loggedIn', '1');
		sessionStorage.setItem('authToken', authToken);
		sessionStorage.setItem('candidate', JSON.stringify(candidate));
	}

	setLoggedOut() {
		this.loggedIn.next(false);
		sessionStorage.setItem('loggedIn', '0');
		sessionStorage.setItem('authToken', null);
		sessionStorage.setItem('candidate', null);
	}

	isLoggedIn(): Observable<boolean> {
		return this.loggedIn.asObservable();
	}

	isLoggedInForGuard(): boolean {
		return this.loggedIn.value;
	}

	getAuthData(): any {
		return {
			token: sessionStorage.getItem('authToken'),
			candidate: JSON.parse(sessionStorage.getItem('candidate'))
		};
	}

}
