import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ApiService } from '../../services/api.service';
import { UiService } from 'projects/microsite/src/app/services/ui.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-pass',
	templateUrl: './pass.component.html',
	styleUrls: ['./pass.component.scss']
})
export class PassComponent implements OnInit {

	isPrivacyMode = false;
	loadingMode = 'loading';

	// form data
	privacyForm = new UntypedFormGroup({
		privacy: new UntypedFormControl('', Validators.required),
	});

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private apiService: ApiService,
		private ui: UiService
	, public transloco: TranslocoService) { }

	ngOnInit() {
		this.goOnWithToken(null);
	}

	goOnWithToken(privacy: any) {
		this.loadingMode = 'loading';
		const payload = {
			token: this.route.snapshot.paramMap.get('token'),
			privacy: privacy
		};
		this.apiService.loginWithMailToken(payload).subscribe((result) => {
			if (result.success === true) {
				const redirect = this.route.snapshot.paramMap.get('redirect');
				if (redirect !== null) {
					this.router.navigate(['profile/' + redirect]);
				} else {
					this.router.navigate(['profile/my-profile']);
				}
			} else {
				this.router.navigate(['/']);
			}
			if (result.noshow === true) {
				this.ui.showAlert(this.transloco.translate('pass.we_missed_you_on_our_candidateday_if_you_have_any_questions_please_contact_us'));
			}
			this.loadingMode = null;
		},
		(error: any) => {
			if (error.error.status === 403) {
				this.isPrivacyMode = true;
				this.loadingMode = null;
			} else {
				this.router.navigate(['/']);
			}
		});
	}

	onCancel() {
		this.router.navigate(['/']);
	}

	onPrivacySubmit() {
		if (this.privacyForm.valid === true) {
			this.goOnWithToken(true);
		}
	}

}
