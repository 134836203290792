<ng-container *transloco="let t">
	<ng-content select=".input-field"></ng-content>
	<div class="label uk-grid-small" uk-grid *ngIf="microsite !== true">
		<div class="uk-width-expand">
			<div class="icons">
				<span class="icon-error" uk-icon="icon: warning; ratio: 0.8"></span>
				<span class="icon-success" uk-icon="icon: check; ratio: 0.8"></span>
				<span class="icon-busy" uk-spinner="ratio: 0.4"></span>
			</div>
			<div class="label-text">
				{{label}}
			</div>
			<div class="error-list" *ngIf="errors !== null">
				<span *ngIf="errors['required']">{{ t('sblib.forms.errors.required', { label }) }}<br></span>
				<span *ngIf="errors['email']">{{ t('sblib.forms.errors.email_not_valid') }}<br></span>
				<span *ngIf="errors['email-exists']">{{ t('sblib.forms.errors.email_already_in_use') }}<br></span>
				<span *ngIf="errors['phonenumber-invalid']">{{ t('sblib.forms.errors.field_invalid', { label }) }}<br></span>
				<span *ngIf="errors['min']">{{ t('sblib.forms.errors.min') }}<br></span>
				<span *ngIf="errors['max']">{{ t('sblib.forms.errors.max') }}<br></span>
				<span *ngIf="errors['pattern']">{{ t('sblib.forms.errors.pattern') }}<br></span>
				<span *ngIf="errors['notSame']">{{ t('sblib.forms.errors.notSame') }}<br></span>
				<span *ngIf="errors['tobeforefrom']">{{ t('sblib.forms.errors.tobeforefrom') }}</span>
				<span *ngIf="errors['fromafterto']">{{ t('sblib.forms.errors.fromafterto') }}</span>
				<span *ngIf="errors['shifValue']">{{ t('sblib.forms.errors.shifValue') }}</span>
				<span *ngIf="errors['passwordLength']">{{ t('sblib.forms.errors.passwordLength') }}</span>
				<span *ngIf="errors['passwordStrength']">{{ t('sblib.forms.errors.passwordStrength') }}</span>
				<span *ngIf="errors['passwordMatch']">{{ t('sblib.forms.errors.passwordMatch') }}</span>
				<span *ngIf="errors['passwordWrong']">{{ t('sblib.forms.errors.passwordWrong') }}</span>
				<span *ngIf="errors['passwordHistory']">{{ t('sblib.forms.errors.passwordHistory') }}</span>
				<span *ngIf="errors['passwordSame']">{{ t('sblib.forms.errors.checkbox_required') }}</span>
			</div>
		</div>
		<div class="uk-width-auto uk-text-right">
			<ng-content select=".input-functions"></ng-content>
		</div>
	</div>
</ng-container>
