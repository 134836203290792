<ng-container *transloco="let t">
  <div
    class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle"
    style="overflow: scroll"
  >
    <div class="uk-width-1-1 uk-width-1-2@m">
      <div class="uk-text-center">
        <p class="icon">
          <span uk-icon="icon: reply; ratio: 4" style="color: red"></span>
        </p>
        <h2>{{ t("sblib.ui.decline.reject") }}</h2>
        <p>
          {{ t("sblib.ui.decline.reject_message") }}
        </p>
      </div>
      <div class="reason-list">
        <sb-ui-listselector
          [values]="reasons.declined"
          [active]="activeReasons"
          (clicked)="setReason($event)"
        ></sb-ui-listselector>
      </div>
      <div uk-grid class="uk-grid-small uk-child-width-1-2">
        <div>
          <button class="uk-button uk-width-1-1 clear" (click)="close()">
            <span uk-icon="icon: close"></span> {{ t("sblib.common.cancel") }}
          </button>
        </div>
        <div>
          <button
            class="uk-button uk-button-primary uk-width-1-1"
            (click)="decline()"
          >
            <span *ngIf="busy !== true" uk-icon="icon: reply"></span>
            <div *ngIf="busy === true" uk-spinner></div>
            {{ t("sblib.common.reject") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
