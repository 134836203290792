<div id="collectModal" class="uk-modal-container" uk-modal>
  <div id="modalContainer" class="uk-modal-dialog">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header" *ngIf="candidateevent && candidateevent.event">
      <h2 class="uk-modal-title">{{ candidateevent.event.name }}</h2>
    </div>

    <div class="uk-modal-body" *ngIf="candidateevent && candidateevent.event">
      <app-prereg-day
        *ngIf="candidateevent.event.is_candidate_day === true"
        [init]="init"
        [mode]="PreregModes.DATACOLLECTION"
        [candidateevent]="candidateevent"
        [event]="candidateevent.event"
        (collected)="onCollected($event)"
      ></app-prereg-day>
      <app-prereg-friday
        *ngIf="candidateevent.event.is_candidate_friday === true"
        [init]="init"
        [mode]="PreregModes.DATACOLLECTION"
        [candidateevent]="candidateevent"
        [event]="candidateevent.event"
        (collected)="onCollected($event)"
      ></app-prereg-friday>
    </div>
  </div>
</div>
