import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../../services/app.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent implements OnInit {

  public content: any;

	constructor(
    private app: AppService,
    private sanitizer: DomSanitizer
  ) { }

	ngOnInit() {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.app.getFooterContent());
	}

}
