import { Deserializable } from './deserializable.model';

export class OfferletterModel implements Deserializable {
	// columns
	id: string;
	name: string;

	deserialize(input: any): this {
		Object.assign(this, input);
		return this;
	}

}



