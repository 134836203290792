<ng-container *transloco="let t;">
  <form class="uk-form-stacked" novalidate (submit)="next()">
    <div class="uk-modal-body">

		<h2>{{ t('common.labels.eeo_topline') }}</h2>
		<p>{{ t('common.labels.eeo_information') }}</p>
		<div uk-grid class="uk-grid-small uk-child-width-1-2@m">
			<div>
				<sb-forms-select [control]="form.get('eeo1')" [label]="t('common.labels.disability')" [options]="options['Disability']"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="form.get('eeo2')" [label]="t('common.labels.disabled_veteran')" [options]="options['Disabled Veteran']"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="form.get('eeo3')" [label]="t('common.labels.gender')" [options]="options['Gender']"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="form.get('eeo5')" [label]="t('common.labels.military_status')" [options]="options['Military Status']"></sb-forms-select>
			</div>
			<div>
				<sb-forms-select [control]="form.get('eeo6')" [label]="t('common.labels.race_ethnicity')" [options]="options['Race/Ethnicity']"></sb-forms-select>
			</div>
			<div>
				<sb-forms-datepicker [control]="form.get('eeo4')" [label]="t('common.labels.military_discharge_date')" [min]="min" [max]="max"></sb-forms-datepicker>
			</div>
		</div>

    </div>
    <div class="uk-modal-footer">
      <div uk-grid>
        <div class="uk-width-1-2@m">
          <p class="uk-text-small"><span uk-icon="icon: info"></span> {{ t('common.labels.fill_all_fields') }}</p>
        </div>
        <div class="uk-width-1-2@m uk-text-right">
          <button type="button" class="uk-button kn-button" (click)="back()">{{ t('common.labels.back') }}</button>&nbsp;
          <button type="submit" class="uk-button kn-call-to-action">{{ t('common.labels.next') }}</button>
        </div>
      </div>
    </div>
  </form>
</ng-container>
