import { NgModule } from '@angular/core';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { SbCoreModule } from './modules/core/core.module';
import { SbFormsModule } from './modules/forms/forms.module';
import { SbUiModule } from './modules/ui/ui.module';
import { SbLibComponent } from './sb-lib.component';

@NgModule({
  declarations: [SbLibComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
  ],
  imports: [TranslocoModule],
  exports: [SbUiModule, SbCoreModule, SbFormsModule],
})
export class SbLibModule {}
