import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input } from '@angular/core';

import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';
import { ApiService } from '../../services/api.service';

import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';
import { JobtypeModel } from '../../../../../sb-lib/src/lib/models/jobtype.model';
import { JoblocationModel } from '../../../../../sb-lib/src/lib/models/joblocation.model';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss'],
})
export class EventComponent implements OnInit {
  loggedIn = false;

  @Input() mode: string = null;
  @Input() event: EventModel = null;

  get headerImage(): string {
    return this.event?.header_image || 'assets/layout/images/event_header.jpg';
  }

  constructor(
    private loginService: LoginService,
    private ui: UiService,
    private api: ApiService,
    public transloco: TranslocoService
  ) {}

  ngOnInit() {
    this.loginService
      .isLoggedIn()
      .subscribe((loggedIn) => (this.loggedIn = loggedIn));
  }

  openDetails(
    tab: string = 'overview',
    jobtype: JobtypeModel = null,
    joblocation: JoblocationModel = null
  ) {
    this.ui.setEventDetail(this.event, tab, jobtype, joblocation);
  }

  showJobType(jobtype: JobtypeModel) {
    this.openDetails('jobs', jobtype);
  }

  showJobLocation(joblocation: JoblocationModel) {
    this.openDetails('locations', null, joblocation);
  }

  addToEventsList() {
    this.api.linkCandidateEvent(this.event.id).subscribe(
      (result) => {
        this.event.candidate_linked = true;
        this.ui.addMessage(result.message, 'success', 'event' + this.event.id);
      }
    );
  }

  requestRemoveFromEventList() {
    this.ui.showConfirm(
      this.transloco.translate(
        'partials.event.really_remove_event_from_your_event_list'
      ),
      () => {
        this.api.deleteCandidateEvent(this.event.id).subscribe(
          (result) => {
            this.event.candidate_linked = false;
            this.ui.addMessage(
              result.message,
              'success',
              'event' + this.event.id
            );
          }
        );
      }
    );
  }
}
