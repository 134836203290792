import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';

import { ApiService } from '../../services/api.service';
import { LoginService } from '../../services/login.service';
import { UiService } from '../../services/ui.service';

import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';
import { JoblocationModel } from '../../../../../sb-lib/src/lib/models/joblocation.model';
import { JobtypeModel } from '../../../../../sb-lib/src/lib/models/jobtype.model';
import { GOOGLE_MAPS_OPTIONS } from '../../constants';


declare var UIkit: any;

@Component({
	selector: 'app-eventdetail',
	templateUrl: './eventdetail.component.html',
	styleUrls: ['./eventdetail.component.scss']
})
export class EventdetailComponent implements OnInit {

	event: EventModel = null;
	activeTab: string = null;
	jobtypeActive: JobtypeModel = null;
	joblocationActive: JoblocationModel = null;

	isLoggedIn = false;
	modal: any = null;
  mapOptions: google.maps.MapOptions = GOOGLE_MAPS_OPTIONS;

	constructor(
		private apiService: ApiService,
		private loginService: LoginService,
		private ui: UiService
	, public transloco: TranslocoService) { }

	ngOnInit() {
		this.modal = UIkit.modal('#eventModal', {
			bgClose: false,
			escClose: false,
		});
		UIkit.util.on('#eventModal', 'hidden', () => {
			this.ui.setEventDetail(null);
		});
		this.loginService.isLoggedIn().subscribe(loggedIn => {
			this.isLoggedIn = loggedIn;
		});
		this.ui.getEventDetailStatus().subscribe(status => {
			if (status !== null) {
				this.event = status.event;
				if (this.event !== null) {
					if (status.jobtype !== null) {
						this.setJobtype(status.jobtype);
					} else if (this.event.jobtypes.length > 0) {
						this.setJobtype(this.event.jobtypes[0]);
					}
					if (status.joblocation !== null) {
						this.setJoblocation(status.joblocation);
					} else if (this.event.joblocations.length > 0) {
						this.setJoblocation(this.event.joblocations[0]);
					}
					this.modal.show();
				} else {
					this.resetModal();
				}
				this.activeTab = status.tab;
			}
		});
	}

	setTab(tab: string) {
		this.ui.setEventDetail(this.event, tab);
	}

	setJobtype(jobtype: JobtypeModel) {
		this.jobtypeActive = jobtype;
	}

	setJoblocation(joblocation: JoblocationModel) {
		this.joblocationActive = joblocation;
	}

	resetModal() {
		this.setJobtype(null);
		this.setJoblocation(null);
	}



}
