import { Component } from '@angular/core';

import { CommonComponent } from '../common/common.component';

@Component({
  selector: 'sb-forms-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent extends CommonComponent {

	getPhoneValue(): string {
		return this.fh.phoneToValue(this.control.value, false);
	}

}
