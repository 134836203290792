import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { LoginService } from '../services/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(
		private loginService: LoginService
	) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const authData = this.loginService.getAuthData();

		const clonedRequest = req.clone({
			headers: req.headers.set(
				'Authorization', 'Bearer ' + authData.token)
		});
		return next.handle(clonedRequest);
	}

}
