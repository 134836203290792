import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as moment from 'moment-timezone';

import { ApiService } from '../../../../src/app/services/api.service';

import { EventModel } from '../../../../../sb-lib/src/lib/models/event.model';
import { SlotModel } from '../../../../../sb-lib/src/lib/models/slot.model';

@Component({
	selector: 'app-slotselector',
	templateUrl: './slotselector.component.html',
	styleUrls: ['./slotselector.component.scss']
})
export class SlotselectorComponent implements OnInit {

	slotListStatus = 'no';
	slotDataLoading: boolean = null;
	slotData: any = null;
	selectedDay: any = null;
	selectedSlot: SlotModel = null;

	constructor(
		private api: ApiService
	, public transloco: TranslocoService) { }

	@Input() event: EventModel = null;
	@Input() mode: string = null;
	@Input() day: any = null;
	@Input() slot: any = null;
	@Output() set = new EventEmitter<SlotModel>();

	ngOnInit() {
		this.slotDataLoading = true;
		this.api.getFreeslots(this.event.id).subscribe(result => {
			this.slotData = result;
			this.slotDataLoading = false;
			if (this.day !== null && this.day !== undefined) {
				this.setDay(this.day, this.slot);
			} else if (this.slotData.length > 0) {
				this.setDay(this.slotData[0]);
			}
		});
	}

	setDay(day: any, slot: any = null) {
		this.selectedDay = this.slotData.find((element: any) => element.id === day.id);
		if (slot !== null && slot !== undefined) {
			this.setSlot(slot);
		} else if (this.selectedDay.slots.length > 0) {
			this.setSlot(this.selectedDay.slots[0]);
		}
	}

	setSlot(slot: any) {
		this.selectedSlot = slot;
		this.set.emit(this.selectedSlot);
	}

	isMorning(slot: any) {
		return moment(slot).format('A') === 'AM';
	}

	isAfternoon(slot: any) {
		return moment(slot).format('A') === 'PM';
	}

}
