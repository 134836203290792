import { BaseModel } from './base.model';
import { Deserializable } from './deserializable.model';

export class JobtypeModel extends BaseModel implements Deserializable {
	// columns
	id: string;
	jobcode: string;
	kncode: string;
	title: string;
	min_behaviour: Array<string>;
	description: Array<any>;
	export_data: any;
  content: string;
	skills: any[];
	languages: string[];
	avatar_image: string;
  level: number;
  compensation: any;
  trashed: boolean;

	jobgroup_id: number;
	select_value: string; // @todo temp for recruiter_dashboard

	get real_avatar_image() {
		return (this.avatar_image !== null) ? this.avatar_image : 'assets/layout/images/avatar.jpg';
	}

}



