import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormArray, AbstractControl } from '@angular/forms';

import * as moment from 'moment-timezone';

import { VALIDATOR_PATTERN_MIN_ONE_LETTER } from '../../../../services/data.service';
import { EventModel } from '../../../../../../../sb-lib/src/lib/models/event.model';
import { FormhelperService } from '../../../../../../../sb-lib/src/lib/modules/forms/services/formhelper.service';

const MAXYEARS = 60;

@Component({
	selector: 'app-work-history',
	templateUrl: './work-history.component.html',
	styleUrls: ['./work-history.component.scss']
})
export class WorkHistoryComponent implements OnInit {

	@Input() event: EventModel;
	@Input() data: any;

	@Output() dataChange = new EventEmitter();

	form = new UntypedFormGroup({});
	min: any = null;
	max: any = null;

	constructor(
		private fb: UntypedFormBuilder,
		private fh: FormhelperService,
	  public transloco: TranslocoService
  ) { }

	ngOnInit() {
		this.max = moment();
		this.min = moment().subtract(MAXYEARS, 'years');
		const career: UntypedFormArray = this.fb.array([]);
		this.form = this.fb.group({
			nocareer: new UntypedFormControl(''),
			career,
		});
		this.addCareerEntry();
	}

	back() {
		this.setData(1);
		this.dataChange.emit(this.data);
	}

	next() {
		if (this.form.valid) {
			this.setData(3);
			this.dataChange.emit(this.data);
		} else {
			this.fh.validateAllFormFields(this.form);
		}
	}

	private setData(step: any) {
		this.data.step = step;
		if (this.form.get('nocareer').value === true) {
			this.data.candidate.work = [];
		} else {
			const career = this.form.get('career').value;
			career.forEach((entry: any) => {
				entry.from = moment(entry.from).format('YYYY-MM-DD');
				entry.to = moment(entry.to).format('YYYY-MM-DD');
			});
			this.data.candidate.work = career;
		}
	}

	createCareerEntry(data: any = null): UntypedFormGroup {
		const newGroup = this.fb.group({
			employer: new UntypedFormControl((data !== null) ? data.employer : '', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MIN_ONE_LETTER)]),
			from: new UntypedFormControl((data !== null) ? data.from : ''),
			to: new UntypedFormControl((data !== null) ? data.to : ''),
			occupation: new UntypedFormControl((data !== null) ? data.occupation : '', [Validators.required, Validators.pattern(VALIDATOR_PATTERN_MIN_ONE_LETTER)])
		});
		newGroup.get('from').setValidators([Validators.required, (control: AbstractControl): any => {
      const from = moment(control.value);
      const to = moment(control.parent.get('to').value);
			if (from.isAfter(to)) {
				return { fromafterto: true };
			}
			return null;
		}]);
		newGroup.get('to').setValidators([Validators.required, (control: AbstractControl): any => {
      const to = moment(control.value);
      const from = moment(control.parent.get('from').value);
			if (to.isBefore(from)) {
				return { tobeforefrom: true };
			}
			return null;
		}]);
		return newGroup;
	}

	addCareerEntry() {
		const careerEntries = this.form.get('career') as UntypedFormArray;
		if (careerEntries.length < 3) {
			careerEntries.push(this.createCareerEntry());
		}
	}

	deleteCareerEntry(index: number) {
		const careerEntries = this.form.get('career') as UntypedFormArray;
		if (careerEntries.length > 0) {
			careerEntries.removeAt(index);
		}
	}

	clearCareerData() {
		const careerEntries = this.form.get('career') as UntypedFormArray;
		while (careerEntries.length !== 0) {
			careerEntries.removeAt(0);
		}
	}

	onNoCareerChanged(value: boolean) {
		if (value === true) {
			this.clearCareerData();
		} else {
			this.addCareerEntry();
		}
	}

}
