<ng-container *transloco="let t">
  <div class="uk-height-1-1 uk-flex uk-flex-center uk-flex-middle">
    <div class="uk-width-1-1 uk-width-1-2@m">
      <p>
        {{ t("sblib.ui.signature_field.accept_message") }}
      </p>
      <div class="signature-pad">
        <signature-pad #signature [options]="signaturePadOptions" (drawStart)="drawStart($event)" (drawEnd)="drawComplete($event)"></signature-pad>
      </div>
      <div uk-grid class="uk-grid-small uk-child-width-1-2">
        <div>
          <button class="uk-button uk-width-1-1 clear" (click)="clear()">
            <span uk-icon="icon: trash"></span> {{ t("sblib.ui.signature_field.accept_button") }}
          </button>
        </div>
        <div>
          <button
            class="uk-button uk-button-primary uk-width-1-1"
            (click)="setSignature()"
          >
            <span uk-icon="icon: check"></span> {{ t('sblib.common.OK') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
