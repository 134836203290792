import { Deserializable } from './deserializable.model';

import { EventModel } from './event.model';
import { EventdayModel } from './eventday.model';
import { SlotlineModel } from './slotline.model';
import { UserModel } from './user.model';

export class SlotModel implements Deserializable {
	// columns
	id: string;
	event_id: string;
	eventday_id: string;
	slotline_id: string;
	recruiter_id: string;
	starts_at: string;
	slot_time: any;
	// relations parent
	event: EventModel;
	eventday: EventdayModel;
	slotline: SlotlineModel;
	recruiter: UserModel;

	deserialize(input: any): this {
		Object.assign(this, input);
		if (this.event !== null && this.event !== undefined) {
			this.event = new EventModel().deserialize(this.event);
		}
		if (this.eventday !== null && this.eventday !== undefined) {
			this.eventday = new EventdayModel().deserialize(this.eventday);
		}
		if (this.slotline !== null && this.slotline !== undefined) {
			this.slotline = new SlotlineModel().deserialize(this.slotline);
		}
		if (this.recruiter !== null && this.recruiter !== undefined) {
			this.recruiter = new UserModel().deserialize(this.recruiter);
		}
		return this;
	}

}



