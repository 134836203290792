import { Component, OnInit, Input } from '@angular/core';
import { faShieldAlt } from '@fortawesome/pro-light-svg-icons';

import { CandidateEventModel } from 'projects/sb-lib/src/lib/models/candidateevent.model';
import { SlotlineModel } from 'projects/sb-lib/src/lib/models/slotline.model';

@Component({
	selector: 'sb-ui-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

	constructor() { }

	@Input() candidateevent: CandidateEventModel = null;
	@Input() slotline: SlotlineModel = null;
	@Input() active: boolean;

  faShield = faShieldAlt;

	ngOnInit() {
	}

}
